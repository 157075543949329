<template >
  <router-link :to="{
    path: '/channeletail',
    name: 'ChannelDetail',
    components: 'ChannelDetail',
    params: { id: code },
  }">
   
      <div class="card">
        <img :src="isLoaded
          ? media_url + cimage.replace('/image', '/images')
          : lazyimage
          " class="customimg" @error="replaceByDefault" @load="loadImage" />
        <div v-show="price > 0" class="topright">
          {{ price }}$
        </div>

      </div>

      <div style="margin-bottom: 20px; color:darkgray"><i class="fa fa-book"></i> {{ qty }} items
      </div>
   </router-link>
</template>
<script>
export default {
  props: [
    "name", "code", "price", "cimage", "qty"
  ],

}
</script>
<style scoped>
.topright {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 12px;
  text-align: center;
  overflow: hidden;
  background: rgb(235, 10, 10);
  border-radius: 15px;
  padding: 3px 10px 3px 10px;
  margin: 0px;
  color: whitesmoke;
  font-weight: bold;
}

.card {
  border-radius: 20px;
  height: 130px !important;
  background-color: lightblue;
  outline: solid lightblue;
  outline-width: 2px;

}

.customimg {
  border-radius: 20px;
  height: 130px !important;

}

#ch {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  justify-content: space-around;

}

.title {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: black;



}
</style>