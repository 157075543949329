<template>
  <div class="waiting">
    <div
      class="spinner-border text-success"
      style="width: 50px; height: 50px"
    ></div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.waiting {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 90vh;
}
</style>