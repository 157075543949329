<template>
  <div class="bl">
    <button class="btn btn-default btn-sm text-light" @click="read()">
      <i class="fa fa-book" aria-hidden="true"></i> {{ lang.lbread }}
    </button>

    <img class="log" :src="logo" />
    <button
      class="btn btn-default btn-sm text-light"
      @click="listen()"
      ref="btnlisten"
    >
      <i class="fa fa-headphones" aria-hidden="true"></i> {{ lang.lblisten }}
    </button>
  </div>
</template>

<script>
export default {
  props: ["book_audio"],
  mounted() {
    if (
      this.globalsetting.server_status == "text-only" ||
      this.globalsetting.server_status == "no-audio" || this.book_audio==1
    ) {
      this.$refs.btnlisten.disabled = true;
    }
  },
  methods: {
    listen() {
      this.$emit("customChange", 0);
    },
    read() {
      this.$emit("customChange", 1);
    },
  },
};
</script>

<style scoped>
.log {
  position: relative;
  justify-content: center;
  align-items: center;
  top: 0px;
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 50%;
}

.bl {
  align-items: center;
  justify-content: space-between;
  display: flex;
  background-color: #0b3c67;
  border-radius: 10px;
  margin: 1px;
  color: white;
  font-weight: bolder;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  height: 44px;
}
</style>