import { createRouter, createWebHistory } from 'vue-router'
import Home from "@/views/Home.vue";
import BookDetail from "@/views/BookDetail.vue";
import Message from "@/views/Message.vue";
import MyLibrary from "@/views/MyLibrary.vue";
import Chapters from "@/views/Chapters.vue";
import PageNotFound from '@/views/PageNotFound.vue';
import OrderSuccess from '@/views/OrderSuccess.vue';
import BookCategory from '@/views/BookCategory.vue';
import ReadBook from '@/views/ReadBook.vue';
import ListenBook from '@/views/ListenBook.vue';
import About from '@/views/About.vue';
import CheckOut from '@/views/Checkout.vue';
import Channel from '@/views/Channel.vue';
import ChannelDetail from '@/views/ChannelDetail.vue';
import ChannelCheckout from '@/views/ChannelCheckout.vue';
import ChannelOrderSuccess from '@/views/ChannelOrderSuccess.vue';
const routes = [
  {
    path: "/",
    name: 'Home',
    component: Home,
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/ordersuccess/:bookid",
    name: 'OrderSuccess',
    component: OrderSuccess,
    props: true
  },

  {
    path: "/bookdetail/:id",
    name: 'BookDetail',
    props: true,
    component: BookDetail,
    meta: { toTop: true, smoothScroll: true, keepAlive: false }

  },
  {
    path: "/message/:msg",
    name: "Message",
    component: Message,
    props: true
  },
  {
    path: "/chapters/:bookid",
    name: "Chapters",
    component: Chapters,
    props: true,
    meta: { keepAlive: false }
  },
  {
    path: "/mylibrary/:userid",
    name: 'MyLibrary',
    component: MyLibrary,
    props: true,
    meta: { keepAlive: false }

  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: PageNotFound

  },
  {
    path: '/bookcategory/:catid',
    name: 'BookCategory',
    component: BookCategory,
    props: true,
    meta: { keepAlive: false }
  },
  {
    path: '/readbook',
    name: 'ReadBook',
    component: ReadBook,
    meta: { toTop: true, smoothScroll: true ,keepAlive:false}

  },
  {
    path: '/listenbook',
    name: 'ListenBook',
    component: ListenBook,
    meta: { keepAlive: false }
    

  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/checkout',
    name: 'CheckOut',
    component: CheckOut,
    meta: { keepAlive: false }
  },
  {
    path: '/channel',
    name: 'Channel',
    component: Channel,
    meta: { keepAlive: false }
  },
  {
    path: '/channeldetail/:id',
    name: 'ChannelDetail',
    component: ChannelDetail,
    props: true,
    meta: { keepAlive: false }
  },
  {
    path: '/channelcheckout',
    name: 'ChannelCheckout',
    component: ChannelCheckout,
    meta: { keepAlive: false }
  },
  {
    path: '/channelordersuccess/:chid',
    name: 'ChannelOrderSuccess',
    component: ChannelOrderSuccess,
    props: true,
    meta: { keepAlive: false }
  }
];
const router = createRouter({
  history: createWebHistory('/#/'), routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0, behavior: 'smooth' }
  }
})

export default router