<template>
    <div v-if="!asyncStatus_ready">
        <Waiting />
    </div>
    <div v-else class="container">
        <div v-if="about">

            <div v-if="about.image" style="display:flex; justify-content:center;">
                <img :src="about.image" style="width: 100vw;" />
            </div>
            <div v-else style="display:flex; justify-content:center;"><img :src="logo"
                    style="width: 100px;height:100px;" />
            </div>

            <div style="text-align:justify;">{{ lang.id == 2 ? about.about : about.abouten }}</div>
            <hr style="color:gainsboro;" />
            <div><i class="fa fa-phone-volume"></i>&nbsp; +855 85 88 72 66</div>
            <div><i class="fa fa-at"></i><span style="color:blue;">&nbsp; {{ about.email }} </span></div>
            <div><i class="fa-brands fa-facebook"></i><span style="color:blue;">&nbsp;
                    {{ about.facebook }}</span></div>
            <div style="display:flex; justify-content:flex-start;">
                <div style="padding-right:8px;"><i class="fa fa-location-dot"></i></div>
                <div>
                    {{ lang.id == 2 ? about.location : about.locationen }}</div>
            </div>
        </div>
        <div style="position:fixed;bottom: 5em; right:0em;padding:8px; color: darkgray; font-size: 10px;">
            {{ lang.lbversion + ' ' +
        appversion
            }}</div>
        <Footer />
    </div>
</template>

<script>
import Footer from '@/layouts/Footer.vue';
import mlogo from "@/assets/logo.png";
import Waiting from "@/components/CWaiting.vue";
import { mapState } from "vuex";
export default {
    setup() {
        const logo = mlogo;
        return { logo }
    },
    components: {
        Footer,
        Waiting
    }, created() {
        if (!this.about) {
            this.$store.dispatch("getabout");
        }
        this.asyncDatafetchedStatus();

    },
    computed: {
        ...mapState({
            about: (state) => state.about
        })
    },


}
</script>

<style scoped>
div {
    padding-bottom: 8px;
}
</style>