<template>
  <div :v-if="book.num_of_chapter > 3 && book.views > 500">
    <router-link :to="{
        path: '/bookdetail',
        name: 'BookDetail',
        components: 'BookDetail',
        params: { id: book.id },
      }">
      <div class="card" style="width: 100px; height: 100px">
        <img :src="isLoaded
            ? media_url + book.image.replace('/image', '/images')
            : lazyimage
          " class="customimg" @error="replaceByDefault" @load="loadImage" />

      </div>
      <div class="booktitle">{{ book.title.split("-")[0] }}</div>

    </router-link>
    <div></div>
  </div>
</template>
<script>
export default {
  props: ["book"],
  methods: {},
  data() {
    const formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return {
      priceafter: parseFloat(
        formatter.format(
          this.book.price - (this.book.price * this.book.discounted_price) / 100
        )
      ),
    };
  },
};
</script>
<style scoped>
.topright {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 12px;
  text-align: center;
  overflow: hidden;
  background: rgb(235, 10, 10);
  border-radius: 50% 20% / 10% 40%;
  padding: 3px 10px 3px 10px;
  margin: 0px;
  color: whitesmoke;
  font-weight: bold;
}

.card {
  border-radius: 20px;
  height: 110px !important;
  margin: 4px;
}

.customimg {
  border-radius: 20px;
  height: 110px !important;

}

.booktitle {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90px;
  color: black;
  text-align: center;
  padding: 2px;

}
</style>
