<template>
  <Carousel :autoplay="20000" :wrap-around="true">
    <Slide v-for="bimg in banners" :key="bimg">
      <div style="width: 100%;"><img :src="'https://img.bookeyond.com/' + bimg.image" class="img"></div>
    </Slide>

  </Carousel>
</template>

<script>
/* eslint-disable no-unused-vars */
import { Carousel, Pagination, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
export default {
  components: {
    Carousel,
    Slide,
  },
  activated() {

  }
};
</script>

<style scoped>
.img {
  width: 100%;
  height: 150px;
}
</style>