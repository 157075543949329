<template>
    <div v-if="!connected">
        <CInternetConnection />
    </div>
    <div id="main" style="font-family:'Inter' !important;" v-else>
        <div v-show="errormsg" class="alert alert-danger" style="font-weight:bold">{{ errormsg }}</div>
        <!--book -->
        <TitleComponent :title="objcheckout.name" :image="objcheckout.profile.replace('/image', '/images')" />
        <div class="cwhbox">
            <div style="font-size:14px;font-weight:bold;padding-bottom:6px;">{{ lang.lbsumary }}</div>
            <div
                style="display:flex; justify-content: space-between; align-items: center;padding-bottom:6px;line-height: 17px;">
                <div style="color: darkgray;font-size: 14px;">{{ lang.duration }}
                </div>
                <div style="font-weight: bold;font-size: 14px;">{{ objcheckout.duration + ' ' + lang.month
                }} </div>
            </div>
            <div
                style="display:flex; justify-content: space-between; align-items: center;padding-bottom:6px;line-height: 17px;">
                <div style="color: darkgray;font-size: 14px;">{{ lang.lbprice }}
                </div>
                <div style="font-weight: bold;font-size: 14px;">{{
                    parseFloat(objcheckout.price).toFixed(2)
                }} {{ abaAC.currency }}</div>
            </div>
            <div v-if="objcheckout.discounted_price && objcheckout.discounted_price > 0"
                style="display:flex; justify-content: space-between; align-items: center;padding-bottom:6px;line-height: 17px;">
                <div style="color: darkgray;font-size: 14px;">{{ lang.lbdiscount + ' (' +
                    objcheckout.discounted_price + "%)"
                }}</div>
                <div style="font-weight: bold;font-size: 14px;">{{
                    parseFloat(discountprice).toFixed(2)
                }} USD</div>
            </div>
            <div
                style="display:flex; justify-content: space-between; align-items: center;padding-bottom:6px;line-height: 17px;">
                <div style="color: darkgray;font-size: 14px;">{{ lang.lbvat }} (10%)
                </div>
                <div style="font-weight: bold;font-size: 14px;">{{ vat
                }} USD</div>
            </div>
            <hr style="color:darkgray;" />
            <div style="display:flex; justify-content: space-between; align-items: center;">
                <div style="color: darkgray;font-size: 16px;line-height: 19px;">{{ lang.lbtotal }}
                </div>
                <div style="font-weight: bold;font-size: 16px;line-height: 19px;">{{ parseFloat(totalprice).toFixed(2)
                }} USD</div>
            </div>
            <div class="bottom">
                <div v-show="abaAC.accountNumber">
                    <div style="display:flex; justify-content: flex-start;padding-bottom:8px;">

                        <img style="height:18px;width:30px;margin-right: 10px;border-radius: 2.4px;" :src="abaimg" />
                        <span style="font-weight: bold;font-size: 16px;line-height: 18px;">ABA PAY</span>

                    </div>
                    <div style="display:flex; justify-content: space-between;margin-bottom: 12px">
                        <div>
                            <div style="font-weight: bold;font-size: 16px;">{{ lang.lbsavingacc }}
                            </div>
                            <div style="font-size: 12px;line-height: 15px; color:darkgray;font-weight: bold;">{{
                                numberspace(abaAC.accountNumber)
                            }}
                                | {{abaAC.currency}}</div>
                        </div>
                        <div style="font-weight: bold;font-size: 16px; color: #0b3c67;" @click="switchaccount">
                            {{ lang.lbchange }} <i class="fa-solid fa-angle-down"></i> </div>
                    </div>
                </div>
                <button class="btn btn-default" @click="onSubmitUSD"
                    style="font-weight: bold; width: 100%;background-color: #0b3c67;color: white;height:2.4rem; font-size: 16px;line-height: 22px;">{{
                        lang.lbcheckout
                    }} ${{ parseFloat(totalprice).toFixed(2)
}}</button>
            </div>
        </div>
        <div v-if="!asyncStatus_ready">
            <CWaiting style="position: fixed; top:-20%; left:30%;right:30%;" />
        </div>

    </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import CInternetConnection from '@/components/CInternetConnection.vue';
import { mapState } from "vuex";
import CWaiting from '@/components/CWaiting.vue';
import abaimg from '@/assets/aba.png';
import axios from "axios";
import TitleComponent from "@/components/TitleComponentS.vue";
export default {
    name: "Checkout",
    components: {
        CInternetConnection,
        CWaiting,
        TitleComponent
    },
    data() {
        return {
            totalprice: 0,
            abaAC: { accountName: "", accountNumber: "", currency: "" },
            errormsg: null,
            invid: "",
            abaimg: abaimg,
            discountprice: 0.0,
            priceafterdis: 0.0,
            vat: 0,

        };
    },
    computed: {
        ...mapState({
            objcheckout: (state) => state.objcheckout,

        })
    },
    created() {
        this.invid = "BKINV" + Date.now();
        this.$bridge.callHandler("setBarTitle", {
            title: "Bookeyond",
            color: "#FFFFFF",
            bgColor: "#0b3c67",
        });
        this.setprice(this.objcheckout.price);
    },

    methods: {

        numberspace(num) {
            if (num) {
                let numspaced = num.match(/.{1,3}/g);
                return numspaced.join(' ');
            }
        },
        switchaccount() {
            if (!navigator.onLine) {
                this.handleInternet();
            } else {
                this.errormsg = null;
                const payment = {
                    amount: this.totalprice,
                    currency: "USD",
                    account: this.invid,
                    useDefault: false
                };

                if (process.env.VUE_APP_ISDEBUG == "false") {
                    this.$bridge.callHandler("doPayment", payment).then(() => {
                        this.msresult = "Do payment success";
                    });
                }
            }
        },
        onSubmitUSD() {
            if (!navigator.onLine) {
                this.handleInternet();
            } else {
                this.errormsg = null;
                var payment = {
                    amount: this.totalprice,
                    currency: "USD",
                    account: this.invid,
                    useDefault: true
                };
                if (!this.abaAC.accountNumber) {
                    payment = {
                        amount: this.totalprice,
                        currency: "USD",
                        account: this.invid,
                        useDefault: false
                    };
                }
                if (process.env.VUE_APP_ISDEBUG == "false") {
                    this.$bridge.callHandler("doPayment", payment).then(() => {
                        this.msresult = "Do payment success";
                    });
                } else {

                    //test mode
                    const mnote = { status: "success", transactionId: "3439252189" };
                    const pdata = {
                        id: this.objcheckout.id,
                        channelid: this.objcheckout.id,
                        duration: this.objcheckout.duration,
                        price: this.totalprice,
                        userid: this.profile.userid,
                        transactionid: this.invid,
                        inv: this.invid,
                        note: mnote,
                    };
                    this.processpayment(pdata);
                }

            }
        },
        async processpayment(obj) {
            if (!navigator.onLine) {
                this.handleInternet();
            } else {
                this.notasyncDatafetchedStatus();
                try {
                    axios.defaults.headers.common['Authorization'] = this.token;
                    await axios
                        .post(
                            this.$store.state.apiurl + "purchase",
                            JSON.stringify(obj)
                        )
                        .then((response) => {
                            const result = response.data;
                            if (result.status == false) {
                                this.msg_error = result.message;
                            } else {
                                this.$store.dispatch("getmychannellst");
                                //this.ispurchasesuccess = true;
                                this.$router.replace("/channelordersuccess/" + this.objcheckout.id);
                            }
                        });
                } catch (error) {
                    console.log(error);
                }

                this.asyncDatafetchedStatus();
            }
        },
        setprice(price) {
            const formatter = new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
            if (price > 0) {
                if (this.objcheckout.discounted_price) {
                    this.discountprice = (price * this.objcheckout.discounted_price) / 100;
                    this.priceafterdis = formatter.format(price - this.discountprice);
                } else this.priceafterdis = price;
                this.vat = formatter.format(this.priceafterdis * 0.1);
                this.totalprice = parseFloat(this.vat) + parseFloat(this.priceafterdis);

            }
        },

    }
    ,
    mounted() {
        if (navigator.onLine) {
            this.$bridge.callHandler("getDefaultAcc", { currency: "USD", amount: this.totalprice }).then((value) => {
                if (value && value.accountNumber) {
                    this.abaAC = value;
                }
            })
            this.asyncDatafetchedStatus();
            if (process.env.VUE_APP_ISDEBUG == "false") {
                this.$bridge.registerHandler("getStatus", (data) => {
                    if (data != "" && data != null) {

                        const pdata = {
                            id: this.objcheckout.id,
                            channelid: this.objcheckout.id,
                            duration: this.objcheckout.duration,
                            price: this.totalprice,
                            userid: this.profile.id,
                            transactionid: data.transactionId ? data.transactionId : data.trxId,
                            inv: this.invid,
                            note: data,
                            miniversion: this.appversion,
                        };
                        this.processpayment(pdata);
                    }

                });
            }
        }
        
    }
}
</script>

<style scoped>
.bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    right: 0em;
    left: 0em;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 16px;
    padding-bottom: 16px;
    box-shadow: 0px 0px 10px 0px rgb(197, 194, 194);
    background: white;
    font-family: 'Inter' !important;

}

.cwhbox {
    background-color: white;
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 6rem;
    font-family: 'Inter' !important;


}
</style>