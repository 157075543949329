<template>
  <div v-if="!connected">
    <CInternetConnection />
  </div>
  <div v-else>
    <div v-if="!asyncStatus_ready">
      <CWaiting />
    </div>
    <!--:style="{'height':`${maxheight}`}-->
    <div v-else id="homediv">
      <div v-if="!banners" class="container header">
        <div style="padding-left: 10px; text-transform: capitalize">
          {{ lang.lbwelcome }}
          {{
            profile
            ? profile.lastName.toLowerCase() + " " +
            profile.firstName : ""
          }}
        </div>
        <img class="csslogo" :src="logo" />
      </div>
      <SlideShow v-else />
      <div v-if="globalsetting.ann_text" v-bind:class="'alert alert-dismissible fade show  ' +
        'alert-' +
        globalsetting.ann_type
        ">
        <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
        <strong> {{ globalsetting.ann_text }}</strong>
      </div>
      <div style="width: 100%;border-radius: 20px 20px 0px 0px;">
        <div class="container" v-if="categories.length > 0">
          <h5 class="title">{{ lang.lbcollection }}</h5>
          <Categorylist :categories="categories" />

          <booklist :categories="categories" :lstbooks="lstbooks" />
        </div>
      </div>

      <div style="display: block;text-align: end;padding:8px; color: darkgray; font-size: 10px;">
        {{ lang.lbversion + ' ' +
          appversion
        }}</div>
      <Footer />
    </div>
    <div v-if="isshowclosed" style="justify-content: center;
                                                  align-items: center;
                                                  display: flex;
                                                  text-align: center;
                                                  z-index: 2;
                                                  position: fixed;top: 50%; left: 50%;
  transform: translate(-50%, -50%); background-color: rgba(11, 11, 11, 0.364); width: 100%; height: 100%;">
      <div style="background-color: white; border-radius: 10px;">
        <div style="padding: 20px; ">
          <div class="h3 " style="margin-bottom: 12px;">
            Bookeyond
          </div>
          <p>
            {{ this.lang.lbexitmessage }}
          </p>
        </div>
        <div style="display: flex;justify-content: space-around;"><button class="btn"
            style="padding:4px;width: 50%;border: 1px solid rgba(30, 28, 28, 0.223); border-radius: 0px 0px 0px 10px;"
            @click="cancel">{{
              this.lang.lbcancel }}</button><button class="btn text-danger"
            style="width: 50%;border: 1px solid rgba(30, 28, 28, 0.223);border-radius: 0px 0px 10px 0px;"
            @click="closeapp">{{
              this.lang.lbconfirm }}</button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
//import BookCom from "@/components/BookCom.vue";
import mlogo from "@/assets/logo.png";
import CWaiting from "@/components/CWaiting.vue";
import Footer from "@/layouts/Footer.vue";
import CInternetConnection from "@/components/CInternetConnection.vue";
import Categorylist from "@/components/Categorylist.vue";
import booklist from "@/components/booklist.vue";
import SlideShow from "@/components/SlideShow.vue";
//import tempdata from "@/data.json";
export default {
  name: "Home",
  setup() {
    /* eslint-disable no-unused-vars */
    /* const { mbooks, loadbook } = getbooks();
    loadbook();

    return { mbooks };*/
    //  const dt = tempdata;
    //const tempbook0 = dt.books.filter((book) => book.category_id == 3);
    //const tempbook1 = dt.books.filter((book) => book.category_id == 7);

    const logo = mlogo;
    return { logo };
  },
  components: {
    //BookCom,
    CWaiting,
    Footer,
    CInternetConnection,
    Categorylist,
    booklist,
    SlideShow,

  },
  compatConfig: { MODE: 3 },
  created() {
    this.$bridge.callHandler("setBarTitle", {
      title: "Bookeyond",
      color: "#FFFFFF",
      bgColor: "#0b3c67",
    });

    /* window.onpopstate = (event) => {
       if (!history.state.forward || history.state.forward == "/") {
         this.isshowclosed = true;
         if (this.$route.path != "/") {
           this.$router.push("/");
         }
       } else {
         this.isshowclosed = false;
       }
 
 
     }*/
  },


  data() {
    return {
      //profile: null,
      titles: ["ការអភិវឌ្ឍន៍ខ្លួន", "សៀវភៅថ្មី"],
      info: null,
      search: "",
      searchResult: null,
      isloaded: false,
      newscategories: [],
      isshowclosed: false
      // scrollleft: 0,
      // lstbooks: this.$store.state.books,

      // abooks: this.$store.state.sourceData.data.items, // we vuex store
    };
  },

  computed: {
    ...mapState({
      lstbooks: (state) => state.books,
      categories: (state) => state.bookcategories,
    }),
  },
  methods: {
    /* loadscroll(event) {
       const { target } = event;
       if (this.categories.length > 0) {
         if (this.totalcategoryload < this.categories.length) {
           if (target.scrollTop <= target.scrollHeight - target.offsetHeight) {
             const objdiv = {};
             objdiv.scrolltop = target.scrollTop;
             objdiv.scrollheight = target.scrollHeight;
             objdiv.offsetheight = target.offsetHeight;
             this.$store.commit("updatecatload", objdiv);
           }
         }
       }
 
     },
     initialdisplayitems() {
       var userheight = 370;
       var heightfor1item = 250;
       var totalheight = window.innerHeight;
       var candisplayitem = Math.ceil((totalheight - userheight) / heightfor1item)
       this.$store.commit("setinitialcattotal", candisplayitem + 2);
 
     },*/

    filterBooks() {
      if (this.search != "") {
        this.searchResult = this.lstbooks.filter((book) => {
          if (this.selectid > 0) {
            return (
              book.title.toLowerCase().includes(this.search.toLowerCase()) &&
              book.category_id === this.selectid
            );
          } else {
            return book.title.toLowerCase().includes(this.search.toLowerCase());
          }
        });
      } else this.searchResult = null;
    },
    bookbyCats(catid) {
      return this.lstbooks.filter((book) => book.category_id === catid);
    },
    Catbyid(catid) {
      return this.categories.find((category) => category.id === catid);
    },
    reloadprofile() {
      this.$store.dispatch("getprofile", this.$bridge).then(() => {
        if (!this.token) {
          this.handleProfile();
        }
      })

    },
    closeapp() {
      this.$bridge.callHandler("closeApp");
    },
    cancel() {
      this.isshowclosed = false;
      history.pushState({}, "", "");
    }


  },
  watch: {
    categories() {
      this.newscategories = this.categories.filter((cat) => cat.id != 11 && cat.id != 7);

    },
    lstbooks() {
      this.asyncDatafetchedStatus();
    },
    token() {
      if (!this.token) {
        this.reloadprofile();
      }
      this.$store.dispatch("mylibrarylst", this.profile.id);

    },
  },
  activated() {


  },
  mounted() {
    /* if (this.totalcategoryload === 0) {
       this.initialdisplayitems();
     }*/
    if (this.lstbooks.length) {
      this.asyncDatafetchedStatus();
    }
    history.pushState({}, "", "");
    window.onpopstate = () => {
      if (!history.state.forward || history.state.forward == "/") {
        this.isshowclosed = true;
        if (this.$route.path != "/") {
          this.$router.push("/");
        }

      } else {
        this.isshowclosed = false;
      }
    }
  }
};
</script>

<style scoped>
.header {
  align-items: center;
  display: flex;
  justify-content: space-between;

}

.header div {
  max-height: 3.6em;
  line-height: 1.8em;
  overflow: hidden;
  font-weight: bold;
  white-space: normal;
  word-break: break-all;
}


.catbook {
  white-space: nowrap;
  margin: 5px;
  border-radius: 9px;
  padding: 10px;

  text-align: center;
  color: white;
  font-size: 17px;
  font-weight: bold;
}

#searchd {
  top: 0;
  margin-bottom: 3em;
  padding-bottom: 3em;
  z-index: 2;
  color: white;
  left: 0;
  position: fixed;
  height: 70px;
  width: 100%;
}

p {
  text-align: center;
}

#divbook {
  padding: 0px 10px 0px 0px;
}

.button {
  background-color: #999999;
  padding: 1rem;
  border-radius: 0.5rem;
  color: #ffffff;
  text-decoration: none;
}

div.scroll {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  max-height: 175px;
  font-size: 11px;
  text-align: center;
  display: flex;
  flex-flow: row nowrap;
  border-radius: 20px;
  white-space: nowrap;

}

#homediv {
  overflow-y: auto;
  overflow-x: hidden;
  flex-flow: column nowrap;
  white-space: nowrap;
  display: flex;
  scroll-behavior: smooth;
  height: 100%;
}

::-webkit-scrollbar {
  display: none;
  width: 0px;
  /* Remove scrollbar space */
  height: 0px;
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

.myanimationload {
  animation: mymove 1s;
}

@keyframes mymove {
  from {
    opacity: 0.9;


  }

  to {
    opacity: 1;


  }
}
</style>
