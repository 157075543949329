<template>
  <div v-if="!connected">
    <CInternetConnection />
  </div>
  <div v-else>
    <div v-if="!asyncStatus_ready">
      <CWaiting />
    </div>
    <div v-else>
      <div class="buttontopic" @click="toggleclass">
        {{ lang.lbcontent }}
        <i class="fa fa-chevron-right" aria-hidden="true"></i>
      </div>
      <div class="chapterscon closetitle" ref="chapterscon">
        <div class="btitle" @click="toggleclass">
          <div style="padding-bottom: 10px">
            {{ lang.lbcontent }}
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </div>
        <div v-for="(chapter, index) in chapters" :key="chapter">
          <p class="ptitleinactive" @click="switchpage(index)" v-bind:class="this.index === index ? 'active' : ''">
            {{ chapter.name }}
          </p>
        </div>
      </div>
      <div class="paginatop">
        <button ref="topprevbutton" type="button" class="btn btn-default btn-sm" @click="prevpage()" disabled>
          <i class="fa fa-angle-double-left" aria-hidden="true"></i>
        </button>
        {{ index + 1 }} / {{ chapters.length }}

        <button ref="topnextbutton" type="button" class="btn btn-default btn-sm" @click="nexpage()">
          <i class="fa fa-angle-double-right" aria-hidden="true"></i>
        </button>
      </div>
      <div class="content" ref="content">
        <div style="text-align: center; font-weight: bold; padding-top: 10px"
          v-html="cleanhtml(chapters[index].name).trim()"></div>
        <hr style="height: 5px" />
        <div v-html="cleanhtml(chapters[index].content).trim()" />

        <div style="
            justify-content: center;
            align-items: center;
            display: flex;
            padding-bottom: 1em;
          ">
          <button ref="prevbutton" type="button" class="btn actionbut text-light btn-sm" @click="prevpage()" disabled>
            <i class="fa fa-angle-double-left" aria-hidden="true"></i>
          </button>
          &nbsp;{{ index + 1 }} / {{ chapters.length }}&nbsp;

          <button ref="nextbutton" type="button" class="btn actionbut text-light btn-sm" @click="nexpage()">
            <i class="fa fa-angle-double-right" aria-hidden="true"></i>
          </button>
        </div>
      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CWaiting from "@/components/CWaiting.vue";
import Footer from "@/layouts/Footer.vue";
import CInternetConnection from "@/components/CInternetConnection.vue";
export default {
  components: {
    CWaiting,
    Footer,
    CInternetConnection,
  },
  async created() {
    var _currentchannelid = 0;
    if (this.currentchannel != null && this.currentchannel.id > 0) {
      _currentchannelid = this.currentchannel.id;
    }
    const obj = { bookid: this.book.id, userid: this.profile.id, miniversion: this.appversion, channelid: _currentchannelid };
    await this.$store.dispatch("getbookChapters", obj);
    this.asyncDatafetchedStatus();
  },
  computed: {
    ...mapState({
      chapters: (state) => state.chapters,
    }),
  },
  methods: {
    cleanhtml(content) {
      return content.replace("<br>", "");
    },
    closetitle() {
      const e = this.$refs.chapterscon;
      e.classList.remove("opentitle");
      e.classList.toggle("closetitle");
      this.isclose = true;
    },
    toggleclass() {
      const e = this.$refs.chapterscon;
      if (this.isclose == true) {
        e.classList.remove("opentitle");
        e.classList.toggle("closetitle");
        this.isclose = true;
        // e.scrollTop = 0;
      } else {
        e.classList.remove("closetitle");
        e.classList.toggle("opentitle");
        this.isclose = false;
        // e.scrollTop = 0;
      }
    },

    opentitle() {
      const e = this.$refs.chapterscon;
      e.classList.remove("closetitle");
      e.classList.toggle("opentitle");
      this.isclose = false;
    },
    switchpage(page) {
      this.closetitle();
      this.index = page;
      this.$refs.content.scrollTop = 0;
    },
    nexpage() {
      if (this.index < this.chapters.length - 1) {
        ++this.index;
        this.$refs.content.scrollTop = 0;
      }
    },
    prevpage() {
      if (this.index > 0) --this.index;
      this.$refs.content.scrollTop = 0;
    },
  },
  mounted() {
    this.$bridge.registerHandler("closeApp", () => {
      this.handleCloseApp();
    });
  },
  watch: {
    index() {
      if (this.index > 0 && this.index < this.chapters.length - 1) {
        this.$refs.prevbutton.disabled = false;
        this.$refs.nextbutton.disabled = false;
        this.$refs.topprevbutton.disabled = false;
        this.$refs.topnextbutton.disabled = false;
      } else if (this.index == 0) {
        this.$refs.prevbutton.disabled = true;
        this.$refs.nextbutton.disabled = false;
        this.$refs.topprevbutton.disabled = true;
        this.$refs.topnextbutton.disabled = false;
      } else {
        this.$refs.nextbutton.disabled = true;
        this.$refs.prevbutton.disabled = false;
        this.$refs.topnextbutton.disabled = true;
        this.$refs.topprevbutton.disabled = false;
      }
    },
  },
  data() {
    return {
      isclose: true,
      index: 0,
      book: this.$store.state.book,
    };
  },
};
</script>

<style scoped>
.content {
  position: fixed;
  top: 3.3em;
  text-align: left;
  bottom: 2.8em;
  overflow: auto;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 15px;
  line-height: 1.8;
  margin-bottom: 0.5em;
  display: inline;
  z-index: inherit;
}

/*chapterscon closetitle*/
.chapterscon {
  cursor: pointer;
  position: fixed;
  top: 0.5em;
  width: 100%;
  max-height: 80vh;
  background-color: bisque;
  overflow: auto;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 15px;
  transition: width 0.2s;
  z-index: 1;
  scroll-behavior: smooth;
  border-radius: 10px 10px 10px 10px;
  padding-bottom: 5px;
  border-bottom: 3px solid #0b3c67;
  border-top: 1px solid #0b3c67;
}

.buttontopic {
  cursor: pointer;
  position: fixed;
  top: 0.5em;
  font-size: 15px;
  width: 100px;
  height: 35px;
  padding: 5px;
  padding-left: 10px;
  font-weight: bolder;
  border-radius: 10px 10px 10px 0px;
  background-color: #0b3c67;
  color: white;
}

.paginatop {
  position: fixed;
  top: 0.5em;
  right: 0;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 15px;
}

.chapterscon .btitle {
  display: flex;
  justify-content: space-between;
}

p {
  cursor: pointer;
  padding: 5px;
}

p.active {
  border-left: 2px solid red;
  padding: 5px;
}

.ptitleinactive {
  line-height: 1.8em;
}

.closetitle {
  width: 100px;
  height: 35px;
  padding: 5px;
  padding-left: 10px;
  border-radius: 0px 10px 10px 0px;
  background-color: #0b3c67;
  color: white;
  overflow: hidden;
  display: none;
}

.opentitle {
  overflow: auto;
  display: block;
}

.btitle {
  font-weight: bold;
}
</style>