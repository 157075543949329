import axios from "axios";
import { ref } from 'vue';

/* eslint-disable no-unused-vars */
const getBooks = () => {
    const mbooks = ref([]);
    const mbook = ref(null);
    const chapters = ref(null);
    //get list of book
    const loadbook = async () => {
        try {
            await axios
                .get("https://abamini.web141.com/api/bookyondts", {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    mbooks.value = response.data.data.items;
                });
        } catch (error) {
            console.log(error)
        }

    }
    //get bookbase on id from local json sever

    const loadbookinfobyid = async (id, API_URL) => {
        try {

            await axios.post(API_URL + "getbookbyid/" + id).then((response) => {
                mbook.value = response.data.book;
            })

        } catch (error) {
            console.log(error);
        }
    }


    const loadbookbyid = async (id, userid, API_URL) => {
        try {
            const data = { bookid: id, userid: userid };
            console.log(API_URL);
            console.log(data);
            await axios.post(API_URL + "getbookChapters", JSON.stringify(data)).then((response) => {
                chapters.value = response.data.data;
                mbook.value = response.data.book;
                // console.log(chapters.value);
            })

        } catch (error) {
            console.log(error);
        }
    }
    const mylibrarylst = async (userid, API_URL) => {

        try {
            await axios
                .get(API_URL + "getmylibrary/" + userid, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    mbooks.value = response.data.data;


                });
        } catch (error) {
            console.log(error);
        }

    }

    return { mbooks, mbook, chapters, loadbook, loadbookbyid, mylibrarylst, loadbookinfobyid };
}
export default getBooks;
