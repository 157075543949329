<template>
  <div v-if="!connected">
    <CInternetConnection />
  </div>
  <div v-else>
    <div v-if="globalsetting && globalsetting.ann_text" v-bind:class="'alert alert-dismissible fade show  ' +
      'alert-' +
      globalsetting.ann_type
      ">
      <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
      <strong> {{ globalsetting.ann_text }}</strong>
    </div>
    <div v-show="message" v-bind:class="mstyle"
      style="font-size: large;padding-top:10px;padding-bottom: 10px ;text-align: center;">{{ message }}
    </div>
    <div style="display: flex; justify-content: right; padding: 10px;" v-if="!profile.account_id">
      <div v-show="!migrate">
        <button type="button" class="btn btn-default" style="font-weight: bold; background-color: #0b3c67;
                                                                                color: white;" @click="migrate = true">
          <i class="fa fa-arrows-rotate"></i> {{ lang.btnconnect }}
        </button>
      </div>
    </div>
    <div v-show="migrate">
      <div style="padding-left:10px;padding-right: 10px;" class="input-group mb-3">
        <input type="text" autofocus v-model="linkcode" class="form-control" placeholder="Code">
        <button class="btn actionbut" @click="linkaccount()" style="color: whitesmoke;font-weight: bold;"><i
            class="fa fa-arrows-rotate"></i> {{ lang.btnconnect }}</button>

      </div>
      <div style="margin-top:10px;margin-left:10px;margin-right:10px;">{{ lang.command }}<br /><br />

        <img :src="img" style="width:100%;" />

      </div>
    </div>
    <div v-show="!migrate">
      <!--<ul class="nav nav-tabs" style="font-weight: bold;">
        <li class="nav-item" @click="tab = 0" style="width: 50%;">
          <a v-bind:class="tab == 0 ? 'nav-link active' : 'nav-link'">{{ lang.lbbook
          }}</a>
        </li>
        <li class="nav-item" @click="tab = 1" style="width: 50%;">
          <a v-bind:class="tab == 1 ? 'nav-link active' : 'nav-link'">{{ lang.channelmenu
          }}</a>
        </li>
      </ul>-->
      <ul
        style="font-weight: bold;padding-top: 0px; text-align: center;display: flex; align-items: center; justify-content: center;">
        <li v-bind:class="tab == 0 ? 'active' : ''" @click="tab = 0">
          {{ lang.lbbook }}
        </li>
        <li v-bind:class="tab == 1 ? 'active' : ''" @click="tab = 1">
          {{ lang.channelmenu }}
        </li>
      </ul>
      <div v-show="tab == 0">
        <div class="container">
          <p></p>
          <p v-show="!libbooks">គ្មានសៀវភៅក្នុងបណ្ណាល័យ</p>
        </div>

        <div class="container" v-for=" book  in  libbooks " :key="book">
          <router-link :to="{
            path: '/bookdetail',
            name: 'BookDetail',
            components: 'BookDetail',
            params: { id: book.id },
          }
            ">
            <div class="row">
              <div class="col">
                <div class="card mb-2">
                  <img :src="isLoaded
                    ? media_url + book.image.replace('/image', '/images')
                    : lazyimage
                    " class="thubmnail img" @error="replaceByDefault" @load="loadImage" />
                </div>
                <div class="col" style="justify-content: start; align-items: center; display: flex;color: black;">
                  <p v-html="book.title.split('-')[0]" class="bookdes"></p>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="container" v-show="tab == 1">

        <p></p>
        <p v-show="!mychannels">គ្មានឆានែលក្នុងបណ្ណាល័យ</p>
        <div id="ch">
          <div v-for=" ch  in  mychannels " :key="ch">
            <ChannelCom :name="ch.name" :code="ch.code" :price="ch.price" :cimage="ch.profile" :qty="ch.number_of_book">
            </ChannelCom>
          </div>
        </div>
      </div>
    </div>
    <div style="display: block;text-align: end;padding:8px;color: darkgray; font-size: 10px;">
      {{ lang.lbversion + ' ' +
        appversion
      }}</div>
    <Footer />
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import Footer from "@/layouts/Footer.vue";
import { mapState } from "vuex";
const axios = require("axios").default;
import CInternetConnection from "@/components/CInternetConnection.vue";
import ChannelCom from "@/components/ChannelCom.vue";
import bkimg from "@/assets/bkcode.jpg";
export default {
  props: { userid: String },
  created() {
    // const { mbooks, mylibrarylst } = getbooks();
    //mylibrarylst(this.profile.id, this.API_URL);
    //this.books = mbooks;
    if (this.libbooks == "") {
      this.$store.dispatch("mylibrarylst", this.userid);
    }
    this.$store.dispatch("getmychannellst");

    this.asyncDatafetchedStatus();
  },
  components: {
    Footer,
    CInternetConnection,
    ChannelCom
  },
  methods: {
    async selectedbook(id) {
      this.reload = true;
      this.book = this.libbooks.find((book) => book.book_id === id);
      const obj = { bookid: this.book.book_id, userid: this.profile.id, miniversion: this.appversion };
      await this.$store.dispatch("getbookChapters", obj);
      this.asyncDatafetchedStatus();
      this.reload = false;
    },
    isIOSDevice() {
      return (
        !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
      );
    },
    async linkaccount() {
      this.notasyncDatafetchedStatus();
      if (this.linkcode.length > 12) {
        axios.defaults.headers.common['Authorization'] = this.token;
        await axios.get(process.env.VUE_APP_API_URL + "linkaccount/" + this.linkcode).then((response) => {
          const result = response.data.data;

          if (result && result.id > 0) {
            this.$store.state.Authuser = result;
            this.migrate = false;
            this.message = response.data.message;
            this.mstyle = "container text-success";
            this.$store.dispatch("mylibrarylst", this.userid);
            this.$store.dispatch("getmychannellst");
          } else {
            this.mstyle = "container text-danger";
            this.message = "Link account is fail";

          }

        });
      }
      this.asyncDatafetchedStatus();
    }

  },
  mounted() { },

  computed: {
    ...mapState({
      libbooks: (state) => state.library,
      chs: (state) => state.chapters,
    }),
  },
  data() {
    return {
      title: "My Library",
      objUser: null,
      book: null,
      reload: false,
      result: "",
      tab: 0,
      migrate: false,
      linkcode: "",
      img: bkimg,
      message: "",
      mstyle: "success"
    };
  },
  watch: {


  }
};
</script>

<style scoped>
#divbook {
  padding-right: 5px;
  padding-left: 5px;
}

.card {
  border-radius: 20px;
  width: 120px;
}

.img {
  border-radius: 20px;
  height: 150px;
  width: 132px;
}

.col {
  display: flex;
}

.col p {
  margin-left: 30px;
}

#ch {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  justify-content: space-around;

}

input:focus {
  box-shadow: none;
}

button:hover {
  box-shadow: none;
}

a.nav-link {
  color: rgb(176, 178, 181);
}

li.active {
  border-bottom: 2px solid #0b3c67;
  color: #0b3c67;
}

li {
  margin: 5px;
  padding: 5px;
  color: lightslategray;
}
</style>