<template>
  <van-tabbar class="footer" v-model="active" active-color="#0b3c67" inactive-color="rgb(176, 178, 181)">
    <van-tabbar-item to="/"><i class="fa fa-home fa-lg"></i> {{ lang.homemenu }}</van-tabbar-item>
    <van-tabbar-item to="/channel"><i class="fa-solid fa-swatchbook"></i> {{ lang.channelmenu }}
    </van-tabbar-item>
    <van-tabbar-item :to="tourl"><i class="fa-solid fa-book-open fa-lg"></i> {{ lang.librarymenu }}
    </van-tabbar-item>
  </van-tabbar>

  <!--<ul class="mobile-list">
    <li class="btn btn-danger">
      <router-link to="/"><i class="fa fa-home fa-lg"></i></router-link>
    </li>
    <li class="btn btn-info">
      <router-link to="/mylibrary"
        ><i class="fa fa-book fa-lg"></i
      ></router-link>
    </li>
  </ul>-->
</template>
<script>
import { Tabbar, TabbarItem } from "vant";
import "vant/lib/index.css";

export default {
  name: "TFooter",
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },

  data() {
    return {
      active: 0,
      tourl: null,

    };
  },
  watch: {
  },
  mounted() {
    /*window.onpopstate = (event) => {

      if (history.state.forward == "/" || !history.state.forward) {
        alert("hi");
        //this.handleCloseApp();
        //console.log(event);
        if (this.$route.path != "/") {
          this.$router.push("/");
        }
      }
    }*/
    this.$bridge.registerHandler("closeApp", () => {
      this.handleCloseApp();
    });
    if (!this.token) {
      this.tourl = "/mylibrary/0";
    } else this.tourl = "/mylibrary/" + this.profile.id;
  },
  methods: {

  },
  created() {
    if (this.$route.name === "Home") {
      this.active = 0;

    } else if (this.$route.name === "Channel") {
      this.active = 1;
    }
    else if (this.$route.name === "MyLibrary") {
      this.active = 2;
    } else this.active = -1;

  },
  activated() {
    if (this.$route.name === "Home") {
      this.active = 0;

    } else if (this.$route.name === "Channel") {
      this.active = 1;
    }
    else if (this.$route.name === "MyLibrary") {
      this.active = 2;
    } else this.active = -1;
  }
};
</script>
<style scoped>
@media only screen and (max-width: 500px) {

  /* when ther screen is bigger than that it won't display*/
  .mobile-list {
    bottom: 0;
    padding: 9px;
    color: white;
    left: 0;
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(54, 52, 52);
  }
}
</style>
