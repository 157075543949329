<template>
  <div
    v-if="!asyncStatus_ready"
    class="spinner-border text-info"
    style="width: 100px; height: 100px"
  ></div>
  <div v-else>
    <div v-if="book">
      <img :src="media_url + book.image" class="img-thumbnail" width="300" />
      <div v-if="chs">
        <SongCom :chapters="chs" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

import getbooks from "@/composable/networkrequest";
import SongCom from "@/components/SongCom.vue";
export default {
  props: { bookid: String },
  setup() {},
  components: {
    SongCom,
  },
  data() {
    return {
      book: null,
      chs: [],
    };
  },

  created() {
    const { mbook, chapters, loadbookbyid } = getbooks();
    /*if (process.env.VUE_APP_ISDEBUG) {
      loadbookbyid(this.bookid, "138084", this.API_URL);
    } else {
      this.$bridge.callHandler("getProfile").then((value) => {
        this.mprofile = value;
        if (this.mprofile.id) {
          loadbookbyid(this.bookid, this.profile.id, this.API_URL);
        }
      });
    }*/
    loadbookbyid(this.bookid, this.profile.id, this.API_URL);
    this.book = mbook;
    this.chs = chapters;
    this.asyncDatafetchedStatus();
  },
  methods: {
    isIOSDevice() {
      return (
        !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
      );
    },
  },
  mounted() {
    if (!this.isIOSDevice()) {
      this.vueInsomnia().on();
    }
  },
};
</script>

<style>
</style>