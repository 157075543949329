import { createStore } from "vuex";
//import sourceData from '@/data.json';
import Languages from "@/assets/dt.json";
const axios = require("axios").default;
import { version } from '../../package';
export default createStore({
    state: {
        //sourceData,
        Authuser: null,
        profile: null,
        lang: Languages.items.find((la) => la.name === 'kh'),
        library: [],
        book: null,
        presult: null,
        books: [],
        bookcategories: [],
        chapters: [],
        apiurl: process.env.VUE_APP_API_URL,
        globalsetting: null,
        generatetime: "",
        total_queries: "",
        total_cached: "",
        lastloadcat: 0,
        totalcategoryload: 100,
        maxheight: 'calc(100vh - 90px)',
        objdiv: null,
        token: "",
        about: null,
        abaerror: "",
        objcheckout: null,
        channels: [],
        mychannels: [],
        currentchannel: null,
        currentchannelbooks: [],
        banners: []

    },
    getters: {
        purchased: state => { return state.presult },
        LoginUser: state => { return state.Authuser },

    },
    actions: {
        //comit to mutation for action
        // get book by id
        async getBookbyid(context, id) {
            try {

                await axios.post(process.env.VUE_APP_API_URL + "getbookbyid/" + id).then((response) => {
                    const book = response.data.book;
                    context.commit('getbook', book)
                    context.commit('getenvsrv', response.data);
                })

            } catch (error) {
                console.log(error);
            }

        },
        async getprofile(context, bridge) {

            try {

                const data = { id: '1002', firstName: 'Kanha', middleName: '', lastName: 'KHIN', email: 'khinkanha1@gmail.com', phone: '016866539', sex: 'F', lang: 'kh', miniversion: version };

                if (process.env.VUE_APP_ISDEBUG == "false") {

                    bridge.callHandler("getProfile").then((value) => {
                        if (value.lang) {
                            context.commit('getlang', value.lang);
                        } else {
                            context.commit('getlang', null);
                        }
                        if (!value.id) {
                            this.state.abaerror = JSON.stringify(value);
                        } else {
                            value.miniversion = version;
                            axios.post(process.env.VUE_APP_API_URL + "getUserInfo", JSON.stringify(value)).then((value) => {

                                context.commit('pullUserbyId', value.data.data);
                                context.commit('getenvsrv', value.data);
                            });
                        }
                    })

                } else {
                    if (data.lang) {
                        context.commit('getlang', data.lang);

                    } else {
                        context.commit('getlang', null);

                    }
                    if (!data.id) {

                        this.state.abaerror = JSON.stringify(data);
                    }
                    await axios.post(process.env.VUE_APP_API_URL + "getUserInfo", JSON.stringify(data)).then((value) => {

                        context.commit('pullUserbyId', value.data.data);
                        context.commit('getenvsrv', value.data);

                    });


                }
            } catch (error) {
                console.log(error)
            }


        },
        async makepurchase(context, objpurchase) {
            try {
                axios.defaults.headers.common['Authorization'] = this.state.token;
                await axios
                    .post(process.env.VUE_APP_API_URL + "purchase", JSON.stringify(objpurchase))
                    .then((response) => {
                        const result = response.data;
                        context.commit('recordPurchase', result);
                        context.commit('getenvsrv', response.data);

                    });
                //query the library 
            } catch (error) {
                console.log(error);
            }

        },
        getbooks(context) {
            try {
                axios.defaults.headers.common['Authorization'] = this.state.token;
                axios
                    .get(process.env.VUE_APP_API_URL + "listbooks/1002")
                    .then((response) => {
                        context.commit('getAllBooks', response.data);
                        context.commit('getenvsrv', response.data);
                    });
                //query the library 
            } catch (error) {
                console.log(error);
            }

        },
        async mylibrarylst(context, userid) {
            try {
                axios.defaults.headers.common['Authorization'] = this.state.token;
                await axios
                    .get(process.env.VUE_APP_API_URL + "getmylibrary/" + userid)
                    .then((response) => {
                        context.commit('getlibrary', response.data.data);
                        context.commit('getenvsrv', response.data);

                    });
            } catch (error) {
                console.log(error);
            }
        },
        async getmychannellst(context) {
            try {
                axios.defaults.headers.common['Authorization'] = this.state.token;
                await axios
                    .get(process.env.VUE_APP_API_URL + "getmychannel")
                    .then((response) => {
                        context.commit('setmychannels', response.data.data);
                        context.commit('getenvsrv', response.data);

                    });

            } catch (error) {
                console.log(error);
            }
        },
        async getbookChapters(context, obj) {
            try {
                axios.defaults.headers.common['Authorization'] = this.state.token;
                await axios.post(process.env.VUE_APP_API_URL + "getbookChapters", JSON.stringify(obj)).then((response) => {
                    context.commit('getChapters', response.data.data);
                    context.commit('getenvsrv', response.data);

                })

            } catch (error) {
                console.log(error);
            }
        },
        async getabout(context) {
            try {
                axios.defaults.headers.common['Authorization'] = this.state.token;
                await axios.get(process.env.VUE_APP_API_URL + "about").then((response) => {
                    context.commit('setabout', response.data);
                })

            } catch (error) {
                console.log(error);
            }
        },

        async getchannels(context, page) {
            try {
                axios.defaults.headers.common['Authorization'] = this.state.token;
                await axios.get(process.env.VUE_APP_API_URL + "channellist/" + page).then((response) => {

                    context.commit('setchlist', response.data.data);
                });

            } catch (error) {
                console.log(error);
            }
        }

    },
    mutations: {
        //do action
        getChapters(state, chapters) {
            state.chapters = chapters;

        },
        getAllBooks(state, data) {
            state.books = data.books;
            state.bookcategories = data.categories;
            state.globalsetting = data.globalsetting;

        },
        pullUserbyId(state, suser) {
            state.profile = suser;
            state.Authuser = suser;
            if (suser && suser.device_token) {
                state.token = suser.device_token;
            } else state.token = null;


        },
        //=================

        recordPurchase(state, result) {
            state.presult = result


        },
        getbook(state, book) {
            state.book = book;

        },
        getlibrary(state, books) {
            state.library = books;
        },
        getlang(state, language) {
            const dtlang = Languages;
            if (language != null) {
                //state.lang = dtlang.items.find((la) => la.name === language);
                state.lang = dtlang.items.find((la) => language.toLowerCase().includes(la.name.toLowerCase()));
            }
        },
        getenvsrv(state, data) {
            if (data.generate_time) {
                state.generatetime = data.generate_time;
                state.total_queries = data.total_queries;
                state.total_cached = data.total_cached;
            }
            state.banners = data.banner;

        },
        updatecatload(state, objdiv) {
            state.lastloadcat = state.totalcategoryload;
            if (state.bookcategories.length === (state.totalcategoryload + 1)) {
                state.maxheight = objdiv.scrollheight;

            }
            state.totalcategoryload = state.totalcategoryload + 1;
            state.objdiv = objdiv;

        },
        setinitialcattotal(state, totalitems) {
            state.totalcategoryload = totalitems;

        },
        setabout(state, data) {
            state.about = data;
        },
        setchlist(state, data) {
            state.channels = data;
        },
        setmychannels(state, data) {
            state.mychannels = data;

        }


    }
});