<template>
  <div class="container" id="songaudio">
    <div class="col" v-if="chapters.length > 0">
      <br />

      <audio
        :src="srcSong"
        accesskey="disable"
        controlsList="nodownload"
        oncontextmenu="return false;"
        type="audio/mpeg"
        controls
        autoplay
        ref="audioPlayer"
      ></audio>
      <p class="text-success">{{ playtitle }}</p>
      <div class="col">
        <!-- <button
          @click="onPlaySong"
          ref="btnpause"
          v-if="srcSong"
          class="btn btn-danger"
        >
          {{ strPause }}</button
        >&nbsp;--><!--<button
          @click="onPlaySong"
          v-if="isPlaying === false"
          class="btn btn-success"
        >
          Play
        </button>-->

        <ul class="list-group" id="listgroup">
          <div v-for="item in chapters" :key="item">
            <li
              href="#"
              class="list-group-item list-group-item-success"
              v-bind:class="item.id == selectedid ? 'active' : ''"
              @click="loadSound(item.audio_url, item.name, item.id)"
            >
              <p class="text-truncate">{{ item.name }}</p>
            </li>
            <!--<div class="row mt-2">
            <button
              @click="loadSound(item.audio_url, item.name)"
              class="btn btn-success"
            >
              <p class="col-span-5">{{ item.name }}</p>
            </button>
          </div>-->
          </div>
        </ul>
      </div>
    </div>
    <div v-else class="text-danger">No audio play yet!</div>
    <!--<div class="col">
      <div class="play-song p-3 bg-white rounded-lg">
        <div class="play-control flex justify-between items-center">
          <div>
            <i
              class="far fa-heart"
              @click="onHeart"
              v-show="isHeart === false"
            ></i>
            <i
              class="fas fa-heart text-red-600"
              @click="onHeart"
              v-show="isHeart === true"
            ></i>
            <i class="fas fa-music"></i>
            <i class="fas fa-expand-alt"></i>
          </div>

          <div
            class="
              play-control__volume
              w-[25%]
              flex
              justify-between
              items-center
            "
          ></div>
        </div>
      </div>
    </div>-->
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import { ref } from "vue";
export default {
  props: ["chapters"],
  created() {},
  methods: {
    onHeart() {
      this.isHeart = !this.isHeart;
    },
    onLeftToggleClick() {
      this.isLeftToggleActive = !this.isLeftToggleActive;
    },
    loadSound(sound, title, id) {
      if (sound) {
        this.selectedid = id;
        this.playtitle = title;
        this.srcSong = sound;
        this.isPlaying = true;
        this.$refs.audioPlayer.play();
      }
    },
    onPlaySong() {
      this.isPlaying = !this.isPlaying;
      if (this.isPlaying) {
        this.$refs.audioPlayer.play();
        this.strPause = "Pause";
        this.$refs.btnpause.classList.value = "btn btn-danger";
      } else {
        this.$refs.audioPlayer.pause();
        this.strPause = "Play";
        this.$refs.btnpause.classList.value = "btn btn-success";
      }
    },
  },
  data() {
    return {
      main: [],
      playtitle: null,
      selectedid: null,
      srcSong: "",
      isHeart: false,
      currentVolume: 30,
      currentTime: 0,
      isPlaying: false,
      strPause: "Pause",
      isLeftToggleActive: false,
      musicSrck: "https://s3.web141.com/anuksa/mysong.mp3",
    };
  },

  mounted() {
    /*    fetch(
      "https://api.apify.com/v2/key-value-stores/EJ3Ppyr2t73Ifit64/records/LATEST?fbclid=IwAR3lI6UNrh62He0eIZeVzJLiQ7fnUkqX7HAKUmrYwxiToUEuHxIXO8PDOwI"
    )
      .then((response) => response.json())
      .then((data) => {
        this.main = data["songs"]["top100_VN"][0]["songs"];
      });*/
  },
};
</script>
<style scoped>
#listgroup {
  text-align: left;
}
#songaudio {
  margin-bottom: 4em;
}
</style>
