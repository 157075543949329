<template>
  <div :v-if="book.num_of_chapter > 3 && book.views > 500">
    <router-link
      :to="{
        path: '/bookdetail',
        name: 'BookDetail',
        components: 'BookDetail',
        params: { id: book.id },
      }"
    >
      <div class="card" style="width: 120px; height: 144px">
        <img
          :src="
            isLoaded
              ? media_url + book.image.replace('/image', '/images')
              : lazyimage
          "
          class="customimg"
          @error="replaceByDefault"
          @load="loadImage"
        />
        <div v-show="book.discounted_price > 0" class="topright">
          {{ book.discounted_price==100?lang.lbfree:"-" + book.discounted_price + "%" }}
        </div>
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          padding-left: 2px;
          padding-right: 2px;
        "
      >
        <p style="color: black; font-weight: bold; font-size: 13px">
          {{ book.discounted_price > 0 ? priceafter.toFixed(2) : book.price }}$
        </p>
        <p class="isdiscount" v-show="book.discounted_price > 0">
          {{ book.price }}$
        </p>
      </div>
    </router-link>
    <div></div>
  </div>
</template>
<script>
export default {
  props: ["book"],
  methods: {},
  data() {
    const formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return {
      priceafter: parseFloat(
        formatter.format(
          this.book.price - (this.book.price * this.book.discounted_price) / 100
        )
      ),
    };
  },
};
</script>
<style scoped>
.topright {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 12px;
  text-align: center;
  overflow: hidden;
  background: rgb(235, 10, 10);
  border-radius: 50% 20% / 10% 40%;
  padding: 3px 10px 3px 10px;
  margin: 0px;
  color: whitesmoke;
  font-weight: bold;
}
.card {
  border-radius: 20px;
  height: 144px !important;
}
.customimg {
  border-radius: 20px;
  height: 144px !important;
}
</style>
