import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import VueBridgeGateway from 'vue-bridge-gateway'
import myplugin from './plugin/myplugin';
import store from '@/store';
import asyncData from "@/mixins/asyncData";
const app = createApp(App);
app.use(router);
app.use(myplugin);
app.use(store);
app.mixin(asyncData);
app.use(VueBridgeGateway, { debug: true, delay: 200 });
app.mount('#app');
//createApp(App).use(router).mount('#app')
