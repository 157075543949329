<template>
  <div class="debug" v-show="querytime">
    {{ querytime ? "TEST Server Generate Time " + querytime : null }}
    Version {{ appversion }}
  </div>
  <router-view v-slot="{ Component }">
    <KeepAlive include="Home">
      <component :is="Component" :key="$route.path" />
    </KeepAlive>
  </router-view>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      abarlog: null,

    };
  },
  components: {},
  async created() {
    this.$store.dispatch("getbooks");
    if (!this.token) {
      await this.$store.dispatch("getprofile", this.$bridge);
    }

  },
  methods: {

  },
  mounted() {
    this.$bridge.registerHandler("redirectPage", (data) => {
      this.abarlog = data;
      if (data.propId) {
        this.$store.dispatch("getBookbyid", data.propId).then(() => {
          this.$router.push("/listenbook");
        });


      }
    });

  },
};
</script>
<style >
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif, "Khmer OS Battambang" !important;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 2em;
  margin-bottom: 2em;
  padding-bottom: 2em;
  top: 0;
  user-select: none;
  line-height: 1.8em;
}

.title {
  font-size: 20px;
  text-align: left;
  padding: 10px;
  font-weight: bold;
}

.paragraph {
  text-align: left;
  display: block;
  text-align-last: left;
  text-justify: inter-character;
  overflow: auto;
}

@media only screen and (max-width: 280px) {
  div.voicestaus {
    display: none;
  }

  div.marq {
    display: none;
  }

  div.audiocontrol {
    width: 130px;
    margin-left: 20px;
    padding: 10px;
  }
}

.csslogo {
  width: 70px;
  height: 70px;
}

@media only screen and (max-width: 220px) {
  #app {
    display: none;
  }
}

@media only screen and (max-width: 230px) {
  p.bookdes {
    display: none;
  }

  .csslogo {
    display: none;
  }
}

.bookdes {
  overflow: hidden;
  font-size: 14px;
  word-wrap: break-word;
  text-overflow: ellipsis;
  display: block;
  transition: 0.3s;
}

.actionbut {
  color: white;
  background-color: #0b3c67;
}

.max-lines {
  display: block;
  /* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 9em;
  line-height: 1.8em;
}

.isdiscount {
  text-decoration-line: line-through;
  color: darkgray;
}

.debug {
  position: fixed;
  top: 1;
  width: 100%;
  z-index: 2;
  background-color: orangered;
  color: whitesmoke;
  text-align: center;
  align-items: center;
  padding: 3px;
  font-weight: bold;
  margin-top: -2em;
  margin-bottom: 2em;
  border-radius: 0px 0px 100px 100px;
}
</style>
