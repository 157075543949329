import lazyload from "@/assets/localimg.png";
import mlogo from "@/assets/logo.png";
import nowifi from "@/assets/nointernet.jpeg";
import { mapState } from "vuex";
import { Dialog } from "vant";
import { version } from '../../package';
export default {
  created() {

  },
  data() {
    return {
      asyncStatus_ready: false,
      lazyimage: lazyload,
      isLoaded: false,
      logo: mlogo,
      nowifiimg: nowifi,
      appversion: version,

    }
  },
  computed: {
    ...mapState({
      lang: (state) => state.lang,
      globalsetting: (state) => state.globalsetting,
      querytime: (state) => state.generatetime,
      lastloadcat: (state) => state.lastloadcat,
      totalcategoryload: (state) => state.totalcategoryload,
      maxheight: (state) => state.maxheight,
      sobjdiv: (state) => state.objdiv,
      profile: (state) => state.Authuser,
      token: (state) => state.token,
      abaerror: (state) => state.abaerror,
      channels: (state) => state.channels,
      mychannels: (state) => state.mychannels,
      currentchannel: (state) => state.currentchannel,
      currentchannelbooks: (state) => state.currentchannelbooks,
      library: (state) => state.library,
      banners: (state) => state.banners,

    }),

  },

  methods: {
    asyncDatafetchedStatus() {
      this.asyncStatus_ready = true;
    },
    notasyncDatafetchedStatus() {
      this.asyncStatus_ready = false;
    },
    replaceByDefault(e) {
      e.target.src = this.lazyimage;
    },
    loadImage() {
      this.isLoaded = true;
    },
    replacehtml(str) {
      let regex = /(<([^>]+)>)/gi;
      return str.replace(regex, "");
    },
    handleCloseApp() {
      Dialog.confirm({
        title: "Bookeyond",
        message: this.lang.lbexitmessage,
        cancelButtonText: this.lang.lbcancel,
        confirmButtonText: this.lang.lbconfirm,
      })
        .then(() => {
          this.$bridge.callHandler("closeApp");
        }).catch(() => {
          console.log("canceled")
        });
    },
    handleInternet() {
      Dialog.alert({
        title: "Bookeyond",
        message: this.lang.lbconnection,
        confirmButtonText: this.lang.lbok,
      }).then(() => {
        // on close
      });

    },
    handleProfile() {
      Dialog.confirm({
        title: "Bookeyond",
        message: this.lang.lbprofilenotfound + this.abaerror,
        confirmButtonText: this.lang.lbok,
        cancelButtonText: this.lang.lbcancel,
      }).then(() => {
        // on close
        this.$bridge.callHandler("closeApp");
      });

    },
  },
}