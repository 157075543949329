<template>
    <div v-for="(cat, index) in categories" :key="cat">
        <div v-if="index <= totalcategoryload" v-bind:class="index <= lastloadcat ? '' : 'myanimationload'">
            <div
                style="display: flex; justify-content: space-between;justify-items: center;font-weight: bold;font-size: 20px;padding: 10px;">
                <div>
                    {{ (cat.name.split("-")[0]).split(" ")[0] }}
                </div>
                <div>
                    <router-link :to="{
                        path: '/bookcategory',
                        name: 'BookCategory',
                        components: 'BookCategory',
                        params: { catid: cat.id },
                    }">

                        <span style="font-size:12px;">{{ lang.lbviewall }}
                            <i class="fa fa-chevron-right" aria-hidden="true"></i></span>

                    </router-link>
                </div>
            </div>

            <div class="scroll" :id="cat.id" ref="mp" v-on:scroll="Xloca">
                <div id="divbook" v-for="book in bookbyCats(cat.id).slice(0, 7)" :key="book">
                    <BookCom :book="book" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BookCom from "@/components/BookCom.vue";
export default {
    props: ["categories", "lstbooks"],
    components: {
        BookCom,
    },
    data() {
        return {
            scrollleft: 0,
            divid: 0
        }
    },
    methods: {
        bookbyCats(catid) {
            return this.lstbooks.filter((book) => book.category_id === catid);
        },
        Xloca(event) {
            const { target } = event;
            this.scrollleft = target.scrollLeft;
            if (target.id != this.divid) {
                this.divid = target.id;
            }
            
        },
    },
    activated() {
        if (this.scrollleft > 0) {
            //this.$refs.mp.scrollLeft = this.scrollleft;
            document.getElementById(this.divid).scrollLeft=this.scrollleft;
            
        }
    },
}
</script>
<style scoped>
#divbook {
    padding: 0px 10px 0px 0px;
}

div.scroll {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    max-height: 175px;
    font-size: 11px;
    text-align: center;
    display: flex;
    flex-flow: row nowrap;
    border-radius: 20px;
    white-space: nowrap;

}

::-webkit-scrollbar {
    display: none;
    width: 0px;
    height: 0px;
    background: transparent;

}

.myanimationload {
    animation: mymove 1s;
}

@keyframes mymove {
    from {
        opacity: 0.9;


    }

    to {
        opacity: 1;


    }
}
</style>