<template>
  <div>{{ msg }}</div>
  <Footer />
</template>

<script>
import Footer from "../layouts/Footer.vue";
export default {
  props: ["msg"],
  components: {
    Footer,
  },
};
</script>

<style>
</style>