<template>
  <div v-if="!connected">
    <CInternetConnection />
  </div>
  <div v-else>
    <div v-if="globalsetting.ann_text" v-bind:class="'alert alert-dismissible fade show  ' +
      'alert-' +
      globalsetting.ann_type
      ">
      <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
      <strong> {{ globalsetting.ann_text }}</strong>
    </div>
    <div v-if="!asyncStatus_ready">
      <CWaiting />
    </div>
    <div v-else>
      <div
        style="justify-content: center;align-items: center;text-align: center; position: relative;padding:12px;display: flex;flex-direction: column; width:100vw;background-color: aliceblue;">
        <div class="card" style="height: 144px;width:fit-content;">
          <img :src="isLoaded
            ? media_url + channel.profile.replace('/image', '/images')
            : lazyimage
            " class="customimg" @error="replaceByDefault" @load="loadImage" />
          <div v-if="csubscribed" class="expire" title="Expire Date">Expire On <br />{{
            dateconvert(csubscribed.subscription_expire_date)
          }}</div>
        </div>
        <div style="margin-top:12px;color: black;font-weight: bold;font-size: 17px;">{{ channel.name }}
        </div>
      </div>
      <div style="margin-left:10px;margin-right:10px;padding-left:8px;padding-right:8px;">
        <div v-if="isbought == false" class="input-group mb-3">
          <!--<input type="number" autofocus v-model="mt" class="form-control" required min=1 max="12"
          :on-change="valuechange()">-->
          <select name="selectnumber" v-model="mt" :on-change="valuechange()" class="form-select">
            <option value=1>1 month</option>
            <option value=6>6 months</option>
            <option value=12>12 months</option>
          </select>
          <button class="btn actionbut" @click="onSubmitUSD()" style="color: whitesmoke;font-weight: bold;"><i
              class="fa fa-cart-shopping"></i>
            {{ (totalprice > 0) ? lang.lbcheckout + " " + totalprice + " $" :
              lang.lbfree }}</button>
        </div>
        <div v-else>
          <div v-if="isbought && csubscribed.is_expired == true" class="input-group mb-3">
            <select name="selectnumber" v-model="mt" :on-change="valuechange()" class="form-select">
              <option value=1>1 month</option>
              <option value=6>6 months</option>
              <option value=12>12 months</option>
            </select>
            <button class="btn actionbut" @click="onSubmitUSD()" style="color: whitesmoke;font-weight: bold;"><i
                class="fa fa-cart-shopping"></i>
              {{ (totalprice > 0) ? lang.lbcheckout + " " + totalprice + " $" :
                lang.lbfree }}</button>
          </div>
        </div>
      </div>
      <div style="margin:10px;padding: 8px;">

        <div class="content">
          <p v-html="cleanhtml(channel.description).trim()"></p>
        </div>
        <div class="title" style="display: flex;justify-content: space-between;">
          <div>សៀវភៅសរុប {{ channel.number_of_book }}ក្បាល</div>
          <div v-show="chbooks.length > 3" @click="expandall()" class="btn btn-default btn-sm"><span
              style="font-size: 12px;font-weight: bold; color: rgb(38, 105, 228);">{{
                lang.lbviewall }}
              <i class="fa fa-chevron-down" aria-hidden="true"></i></span></div>
        </div>
        <div v-bind:class="viewall ? 'expandlst' : 'lst'">
          <div v-for="book in chbooks" :key="book">
            <BookCom :book="book" />
          </div>
        </div>
      </div>
    </div>

  </div>
  <Footer />
</template>
<script>
/* eslint-disable no-unused-vars */
import Footer from "@/layouts/Footer.vue";
import CInternetConnection from "@/components/CInternetConnection.vue";
import CWaiting from "@/components/CWaiting.vue";
const axios = require("axios").default;
import BookCom from "@/components/BookChannelCom.vue";
import moment from "moment";
export default {
  name: "ChannelDetail",
  props: ["id"],
  data() {
    return {
      chbooks: [],
      channel: "",
      note: null,
      txid: null,
      invid: "BKINV" + Date.now(),
      isexist: false,
      discountprice: 0.0,
      priceafterdis: 0.0,
      mt: 1,
      vat: 0,
      totalprice: 0.0, //per year *12 months
      isbought: false,
      csubscribed: "",
      viewall: false,
    }
  },
  created() {
    this.filterchannel(this.id);
    this.channeldetail();

  },
  components: {
    CInternetConnection,
    Footer,
    CWaiting,
    BookCom
  },
  mounted() {

  },
  methods: {
    cleanhtml(content) {
      const cont = content.replace(/(<br>)*/g, "");
      return cont;
    },
    async channeldetail() {
      this.notasyncDatafetchedStatus();
      try {
        axios.defaults.headers.common['Authorization'] = this.token;
        await axios.get(process.env.VUE_APP_API_URL + "channeldetail/" + this.id).then((response) => {
          const dt = response.data;
          this.channel = dt.channel;
          this.chbooks = dt.channel_books;
          this.totalprice = this.channel.price * 2;
          this.$store.state.currentchannel = dt.channel;
          this.$store.state.currentchannelbooks = dt.channel_books;

        });
      } catch (error) {
        console.log(error);
      }
      this.asyncDatafetchedStatus();

    },
    async recordPurchase(obj) {
      obj.miniversion = this.appversion;
      this.notasyncDatafetchedStatus();
      try {
        axios.defaults.headers.common['Authorization'] = this.token;

        await axios
          .post(
            this.$store.state.apiurl + "purchase",
            JSON.stringify(obj)
          )
          .then((response) => {
            const result = response.data;
            if (result.status == false) {
              this.msg_error = result.message;
            } else {
              this.$store.dispatch("getmychannellst");

              this.$router.replace("/channelordersuccess/" + this.channel.id);
            }
          });

      } catch (error) {
        console.log(error);
      }
      this.asyncDatafetchedStatus();
    },
    filterchannel(id) {
      if (this.mychannels && this.mychannels.length > 0) {
        var ch = this.mychannels.find((obj) => obj.code === id);
        if (ch != null && ch.id > 0) {
          this.isbought = true;
          this.csubscribed = ch;
        } else this.isbought = false;
      }

    },
    onSubmitUSD() {
      if (this.token) {
        if (this.isexist == false) {
          this.$store.state.objcheckout = this.channel;
          if (process.env.VUE_APP_ISDEBUG == "false" && this.totalprice > 0) {
            this.channel.price = this.totalprice;
            this.channel.duration = this.mt;
            this.$router.push("/channelcheckout");
          } else {
            //record payment here
            const mnote = { status: "success", transactionId: "3439252189" };
            const pdata = {
              id: this.channel.id,
              channelid: this.channel.id,
              duration: this.mt,
              price: this.totalprice,
              userid: this.profile.id,
              transactionid: this.invid,
              inv: this.invid,
              note: mnote,
            };
            console.log(pdata);
            this.recordPurchase(pdata);
          }
        }
      }
    },
    valuechange() {

      if (this.mt > 0) {
        this.totalprice = this.channel.price * this.mt;
      } else this.mt = 1

    },
    dateconvert(dt) {
      return moment(dt).format('MMM DD, YYYY');
    },
    expandall() {
      this.viewall = !this.viewall;
    }
  },
};
</script>
<style scoped>
input:focus {
  box-shadow: none;
}

select:focus {
  box-shadow: none;
}

::-webkit-scrollbar {
  display: none;
  width: 0px;
  height: 0px;
  background: transparent;
}

.topright {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 12px;
  text-align: center;
  overflow: hidden;
  background: rgb(235, 10, 10);
  border-radius: 50% 20% / 10% 40%;
  padding: 3px 10px 3px 10px;
  margin: 0px;
  color: whitesmoke;
  font-weight: bold;
}

.expire {
  position: absolute;
  width: 100%;
  bottom: 0;
  font-size: 12px;
  text-align: center;
  overflow: hidden;
  color: whitesmoke;
  font-weight: bold;
}

.card {
  border-radius: 20px;
  height: 140px !important;
  background-color: lightblue;
  outline: solid lightblue;
  outline-width: 2px;

}

.customimg {
  border-radius: 20px;
  height: 144px !important;

}

.content {
  text-align: left;
  overflow: auto;
  width: 100%;
  font-size: 15px;
  line-height: 1.3rem;
}

p:empty {
  display: none;

}

.title {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.lst {
  border-radius: 12px;
  display: flex;
  flex-flow: row nowrap;
  overflow: auto;


}

.expandlst {
  border-radius: 12px;
  display: flex;
  flex-flow: row wrap;
  overflow: auto;
  justify-content: space-between;

}
</style>
