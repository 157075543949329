<template>
  <div class="container">
    <div class="nowifi"><i style="margin-bottom:5px;" class="fa-solid fa-triangle-exclamation fa-3x"></i>
      <p style="margin-bottom:5px;">{{  lang.lbconnection  }}</p>
      <button type="button" class="btn btn-light btn-sm" @click="reload()">Reload</button>

    </div>

   </div>
</template>

<script>
export default {
  name: "CInternetConnection",
  components: {
  
  },
  methods: {
    reload() {

      var isconnected = navigator.onLine;
      if (isconnected) {
        if (this.$route.path === "/") {
          this.$router.go(0);
        } else this.$router.go(-1)


      }

    }
  }
};
</script>

<style scoped>
.nowifi {
  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: red;
  line-height: 1.8em;
  height: 90vh;
}
</style>