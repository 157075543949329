<template>
  <div class="container-fluid">
    <div
      class="bgdesign"
      v-bind:style="{
        'background-image': ' url(' + media_url + image + ')',
      }"
    ></div>
    <div style="justify-content: center; align-items: center; display: flex">
      <div class="card">
        <img
          :src="isLoaded ? media_url + image : lazyimage"
          class="img-thubmnail img"
          @error="replaceByDefault"
          @load="loadImage"
        />
      </div>
    </div>
    <div class="hero-text">
      <span class="titlecss">
        {{ title.split("-")[0] }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["image", "title"],
};
</script>

<style scoped>
/*@media only screen and (max-height: 748px) {
  .card {
    top: -0.5em;
    /* height: 17vh !important;
    width: 100px !important;*/
/* }
  .container-fluid {
    height: 25vh !important;
    margin-bottom: 2em;
  }
  .bgdesign {
    height: 25vh !important;
  }
}
@media only screen and (max-height: 533px) {
  .card {
    top: -0.5em;
    height: 23vh !important;
    width: 100px !important;
  }
  .container-fluid {
    height: 25vh !important;
    margin-bottom: 2em;
  }
  .bgdesign {
    height: 200px !important;
  }
}*/
.container-fluid {
  height: 200px;
}
.card {
  border-radius: 20px;
  height: 152px;
  width: 130px;
}
.img {
  border-radius: 20px;
  width: 100%;
  height: 100%;
}

.bgdesign {
  height: 200px; /*220px;*/
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  filter: blur(15px);
}
.hero-text {
  position: relative;
  padding-top: 5px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  color: #0b3c67;
}
.titlecss {
  background-color: rgba(19, 18, 18, 0.027);
  background-blend-mode: soft-light;
  border-radius: 5px;
  line-height: 1.8em;
  max-height: 2em;
  text-overflow: clip;
  overflow: hidden;
}
</style>