<template>

  <div v-if="!connected">
    <CInternetConnection />
  </div>
  <div v-else>
    <div v-if="!asyncStatus_ready">
      <CWaiting />
    </div>
    <div v-else>
      <div class="container" v-if="book">
        <TitleComponent :title="book.title" :image="book.image.replace('/image', '/images')" />
        <div style="text-align: right; font-weight: bold">
        </div>
        <div class="descontent lessons">
          <div v-for="(chapter, i) in chapters" :key="chapter">
            <p class="ptitleinactive" v-bind:class="index == i ? 'active' : ''" @click="playbyuserclick(i)">
              {{ chapter.name }}
            </p>
          </div>
        </div>
      </div>
    </div>

  </div>
  <Footer />
</template>

<script>
import TitleComponent from "@/components/TitleComponent.vue";
import Footer from "@/layouts/Footer.vue";
import CWaiting from "@/components/CWaiting.vue";
import { mapState } from "vuex";
import CInternetConnection from "@/components/CInternetConnection.vue";
export default {
  setup() {
    const audio = new Audio();
    //audio.autoplay = true;
    const AudioSever = "https://media.bookeyond.com/";
    return { audio, AudioSever };
  },
  components: {
    TitleComponent,
    CWaiting,
    CInternetConnection,
    Footer,
  },
  async created() {
    var _currentchannelid = 0;
    if (this.currentchannel != null && this.currentchannel.id > 0) {
      _currentchannelid = this.currentchannel.id;
    }
    const obj = { bookid: this.book.id, userid: this.profile.id, miniversion: this.appversion, channelid: _currentchannelid };
    await this.$store.dispatch("getbookChapters", obj);
    this.asyncDatafetchedStatus();
    this.audio.src = this.AudioSever + "soon.mp3";
    if (this.chapters[this.index].file_path != null) {
      this.audio.src = this.AudioSever + this.chapters[this.index].file_path;
    }
    this.play();
    this.tracks.push(this.audio);
    //this.usilent.play();
    var playid = 0;
    for (let i = 0; i < this.chapters.length; ++i) {
      let _audio = new Audio();
      var atrack = {};
      if (i == 0) {
        playid = this.chapters[i].id;
      }
      atrack.id = this.chapters[i].id;
      atrack.title = this.chapters[i].name;
      atrack.thumbnail =
        this.media_url + this.book.image.replace("/image", "/images");
      atrack.description = "";
      atrack.bookId = this.book.id;
      if (this.chapters[i].file_path != null) {
        _audio.src = this.AudioSever + this.chapters[i].file_path;
        atrack.url = this.AudioSever + this.chapters[i].file_path;
      } else {
        _audio.src = this.AudioSever + "soon.mp3";
        atrack.url = this.AudioSever + "soon.mp3";
      }
      //_audio.load();
      this.tracks.push(_audio);
      this.atracks.push(atrack);
    }
    const setplaylist = { playId: playid, playlist: this.atracks };
    if (process.env.VUE_APP_ISDEBUG == "false") {
      this.$bridge.callHandler("setPlayList", setplaylist);
      this.$bridge.callHandler("switchPlayerMode", { mode: "FULL" });
      this.$bridge.callHandler("getPlayingId").then((data) => {
        this.nativedata = data;
        var kindex = this.atracks.findIndex(
          (chapter) => chapter.id === data.playId
        );
        if (kindex != this.index) {
          this.index = kindex;
        }
        this.book.id = data.playListId;

      });
    }
  },
  beforeUnmount() {
    try {
      //happend before leave
      this.$bridge.callHandler("switchPlayerMode", { mode: "MINI" });
    } catch (error) {
      console.log(error);
    }
  },

  computed: {
    ...mapState({
      chapters: (state) => state.chapters,
    }),
  },

  watch: {
    isconnected() {
      if (this.isconnected == false) {
        this.handleInternet();
      }
    },
    /*switchmode() {
      if (process.env.VUE_APP_ISDEBUG == "false") {
        if (this.switchmode == true) {
          this.$bridge.callHandler("switchPlayerMode", { mode: "FULL" });
        } else this.$bridge.callHandler("switchPlayerMode", { mode: "MINI" });
      }
    },*/
    index() {
      try {
        if (process.env.VUE_APP_ISDEBUG == "false") {
          this.$bridge.callHandler("setAudioPlay", {
            playId: this.atracks[this.index].id,
          });
        } else {
          // this.audio.pause();
          this.percentime = 0;
          this.audio = this.tracks[this.index];
          this.play();
        }
      } catch (error) {
        console.log(error);
      }
      this.isconnected = navigator.onLine;
      /*if (this.ismute) {
        this.audio.muted = true;
      } else {
        this.audio.muted = false;
      }
      if (this.index > 0 && this.index < this.chapters.length - 1) {
        this.$refs.prevbutton.disabled = false;
        this.$refs.nextbutton.disabled = false;
      } else if (this.index == 0) {
        this.$refs.prevbutton.disabled = true;
        this.$refs.nextbutton.disabled = false;
      } else {
        this.$refs.nextbutton.disabled = true;
        this.$refs.prevbutton.disabled = false;
      }*/
    },
    played() {
      try {
        if (this.played == true) {
          this.playpause(true);
        } else {
          this.playpause(false);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },

  methods: {
    canplay() {
      this.play();
    },

    ended() {
      try {
        if (this.index < this.chapters.length - 1) {
          this.index++;
          this.percentime = 0;
        } else if (this.index == this.chapters.length - 1) {
          this.played = false;
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    mute(value) {
      this.ismute = value;
      if (!this.ismute) {
        //false
        this.audio.muted = false;
      } else {
        //true
        this.audio.muted = true;
      }
    },
    cat(id) {
      return this.$store.state.bookcategories.find(
        (category) => category.id === id
      );
    },
    isIOSDevice() {
      return (
        !!Navigator.platform && /iPad|iPhone|iPod/.test(Navigator.platform)
      );
    },

    next() {
      if (this.index < this.chapters.length - 1) {
        this.index++;
        this.percentime = 0;
      }
    },
    pre() {
      if (this.index > 0) {
        this.index--;
        this.percentime = 0;
      }
    },
    playbyuserclick(cindex) {
      this.index = cindex;
      this.percentime = 0;
    },
    play() {
      if (process.env.VUE_APP_ISDEBUG == "true") {
        this.audio.addEventListener("timeupdate", this.updateCurrentTime);
        this.audio.addEventListener("ended", this.ended);
        this.audio.addEventListener("error", (event) => {
          // this.handleInternet();
          console.log(event);
        });
        this.setDuration();
        this.audio.play();
        this.played = true;
        this.paused = false;
        this.tryKeepScreenAlive(15);
      }
    },
    playpause(isplay) {
      if (isplay == true) {
        if (process.env.VUE_APP_ISDEBUG == "true") {
          this.audio.play();
        }
      } else {
        this.audio.pause();
      }
    },
    iphoneremoteaction() {
      this.played = false;
    },
    test() {
      this.audio.download();
    },

    seektime() {
      /* this.$refs.audioPlayer.currentTime =
        (this.$refs.audioPlayer.duration * this.percentime) / 100;*/
      this.audio.currentTime = (this.audio.duration * this.percentime) / 100;
    },
    changevolue() {
      // this.$refs.audioPlayer.volume = this.volume / 100;
      this.audio.volume = this.volume / 100;
    },
    updateCurrentTime() {
      try {
        // const time = this.$refs.audioPlayer.currentTime;

        const time = this.audio.currentTime;
        this.percentime = (time * 100) / this.totalduration;
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time - minutes * 60);
        this.durationPlayed.min = minutes < 10 ? "0" + minutes : minutes;
        this.durationPlayed.sec = seconds < 10 ? "0" + seconds : seconds;
        // this.isconnected = navigator.onLine;
      } catch (error) {
        console.log(error.message);
      }
    },
    setDuration() {
      // const time = this.$refs.audioPlayer.duration;
      try {
        const time = this.audio.duration;
        if (time > 0) {
          this.totalduration = time;
          const minutes = Math.floor(time / 60);
          const seconds = Math.floor(time - minutes * 60);
          this.duration.min = minutes < 10 ? "0" + minutes : minutes;
          this.duration.sec = seconds < 10 ? "0" + seconds : seconds;
        }
      } catch (error) {
        console.log(error.message);
      }
    },
  },

  mounted() {
    //this.audio.addEventListener("timeupdate", this.updateCurrentTime);
    //this.audio.addEventListener("durationchange", this.setDuration);
    // this.audio.addEventListener("ended", this.ended);
    this.$bridge.registerHandler("closeApp", () => {
      this.played = false;
      this.audio.pause();
      this.handleCloseApp();
    });

    if (process.env.VUE_APP_ISDEBUG == "false") {
      // this.$bridge.callHandler("switchPlayerMode", { mode: "MINI" });

      this.$bridge.registerHandler("onPlayIdChange", (data) => {
        this.nativedatachange = data;
        if (data.playId) {
          var kindex = this.atracks.findIndex(
            (chapter) => chapter.id === data.playId
          );
          if (kindex != this.index) {
            this.index = kindex;
          }
          this.book.id = data.playListId;
        }
      });

    }
  },
  data() {
    return {
      index: 0,
      nativedata: null,
      nativedatachange: null,
      book: this.$store.state.book,
      played: true,
      paused: false,
      volume: 100,
      ismute: false,
      percentime: 0,
      previusevolume: null,
      loop: false,
      totalduration: 0,
      isconnected: null,
      duration: {
        min: "00",
        sec: "00",
      },
      durationPlayed: {
        min: "00",
        sec: "00",
      },
      tracks: [],
      atracks: [],
      switchmode: false,
    };
  },
};
</script>

<style scoped>
.masthead .ui.menu .ui.button {
  margin-left: 0.5em;
}

.footer.segment {
  padding: 5em 0em;
}

@media only screen and (max-width: 700px) {
  .secondary.pointing.menu .toc.item {
    display: block;
  }

  .secondary.pointing.menu .item {
    display: none;
  }
}

@media only screen and (max-height: 650px) {
  .descontent {
    max-height: auto !important;
  }
}

p {
  padding: 0px;
  padding-left: 5px;
  cursor: pointer;
}

p.active {
  border-left: 2px solid red;
}

#player {
  bottom: 3.3em;
  padding-right: 7px;
  color: white;
  left: 0;
  position: fixed;
  height: 99px;
  line-height: 1.3em;
  width: 100%;
  background-color: rgb(27, 26, 26);
  margin-bottom: 0.3em;
}

.ui.mini.image {
  min-height: 50px;
  min-width: 50px;
  width: 50px;
  height: 50px;
  margin-left: 10px;
}

.large.icon {
  margin-top: 14px;
}

.big.icon {
  margin-top: 10px;
}

.icon.text {
  margin-top: 15px;
  font-weight: bold;
  font-size: 15px;
}

.sa {
  font-size: 10px;
}

.lessons {
  width: 100%;
  text-align: left;
  margin-bottom: 3.5em;
}

.seekaudio {
  position: fixed;
  bottom: 6.8em;
  left: 0em;
  right: 0em;
  width: 100vw;
  padding: 0em;
}

.csstimedisplay {
  position: relative;
  bottom: 0.5em;
  font-weight: bold;
}

.descontent {
  text-overflow: ellipsis;
  word-wrap: break-word;
  line-height: 1.8em;
}

.ptitleinactive {
  line-height: 1.8em;
}
</style>