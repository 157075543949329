/* eslint-disable no-unused-vars */
//import SecondPage from '../views/SecondPage.vue';
export default {
    install: (app) => {
        /* app.component('mysecond', SecondPage);
 
         const logout = () => {
             console.log("Logout is being call");
 
         };*/

        //app.provide("logout", logout);
        //play with directive
        app.directive("font-size", (el, binding) => {
            var size = 12;
            switch (binding.arg) {
                case "small":
                    size = 12;
                    break;
                case "large":
                    size = 24;
                    break;
                default:
                    size = 18;
                    break;
            }
            el.style.fontSize = size + "px";
        });
        app.mixin({
            data() {
                return {
                    isDebug: process.env.VUE_APP_ISDEBUG,
                    API_URL: process.env.VUE_APP_API_URL,
                    media_url: process.env.VUE_APP_MEDIA_URL,
                    featureLink: "https://khmer.biz",
                    connected: navigator.onLine,
                    jsonheader: {
                        Authorization: "Bearer WREWRX98729432lkdsffa",
                        "Content-Type": "application/json",
                    },
                };
            },
            methods: {
                //logout,

            },
            created() {
                //console.log("Printing from created");

            },
        });

    }
}