<template>
  <div v-if="!connected">
    <CInternetConnection />
  </div>
  <div v-else>
    <div v-if="globalsetting.ann_text" v-bind:class="
      'alert alert-dismissible fade show  ' +
      'alert-' +
      globalsetting.ann_type
    ">
      <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
      <strong> {{ globalsetting.ann_text }}</strong>
    </div>
    <div class="container">
      <h3 class="title">{{ Catbyid(catid).name.split("-")[0] }}</h3>
      <p v-if="!books" style="padding-left: 10px">គ្មានសៀវភៅ</p>
    </div>
    <div class="container" v-for="book in books(catid)" :key="book">
      <router-link :to="{
        path: '/bookdetail',
        name: 'BookDetail',
        components: 'BookDetail',
        params: { id: book.id },
      }">
        <div class="row">
          <div class="col">
            <BookCom :book="book" />

            <div style="
              justify-content: center;
              align-items: center;
              display: flex;
              color: black;
            ">
              <p class="bookdes" v-html="book.title.split('-')[0]" />
            </div>
          </div>
        </div>
      </router-link>
    </div>
    <Footer />
  </div>
</template>
<script>
import BookCom from "@/components/BookCom.vue";
import Footer from "@/layouts/Footer.vue";
import CInternetConnection from "@/components/CInternetConnection.vue";
export default {
  setup() { },
  props: ["catid"],
  created() { },
  components: {
    BookCom,
    CInternetConnection,
    Footer,
  },
  methods: {
    books(catid) {
      return this.$store.state.books.filter(
        (book) => parseInt(book.category_id) === parseInt( catid)
      );
    },
    Catbyid(catid) {
      return this.$store.state.bookcategories.find(
        (category) => parseInt(category.id) === parseInt(catid)
      );
    },
  },
};
</script>
<style scoped>
.card {
  border-radius: 20px;
}

.img {
  border-radius: 20px;
  height: 150px;
  width: 132px;
}

.col {
  display: flex;
}

.col p {
  margin-left: 30px;
}
</style>
