<template>
  <div v-if="!connected">
    <CInternetConnection />
  </div>
  <div v-else>
    <div v-if="globalsetting.ann_text" v-bind:class="'alert alert-dismissible fade show  ' +
      'alert-' +
      globalsetting.ann_type
      ">
      <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
      <strong> {{ globalsetting.ann_text }}</strong>
    </div>
    <div class="container" id="ch">
      <div v-for="ch in channels" :key="ch" class="col-6 p-2">
        <ChannelCom :name="ch.name" :code="ch.code" :price="ch.price" :cimage="ch.profile" :qty="ch.number_of_book">
        </ChannelCom>
      </div>
    </div>
  </div>
  <Footer />
</template>
<script>
/* eslint-disable no-unused-vars */
import Footer from "@/layouts/Footer.vue";
import CInternetConnection from "@/components/CInternetConnection.vue";
import ChannelCom from "@/components/ChannelCom.vue";
import { Dialog } from "vant";
export default {
  data() {
    return {
      page: 1
    }
  },
  created() {
    this.$store.dispatch("getchannels", this.page);
    this.$store.dispatch("getmychannellst");
  },
  components: {
    CInternetConnection,
    Footer,
    ChannelCom
  },
  methods: {
    books(catid) {
      return this.$store.state.books.filter(
        (book) => parseInt(book.category_id) === parseInt(catid)
      );
    },
    Catbyid(catid) {
      return this.$store.state.bookcategories.find(
        (category) => parseInt(category.id) === parseInt(catid)
      );
    },
  },
};
</script>
<style scoped>
.topright {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 12px;
  text-align: center;
  overflow: hidden;
  background: rgb(235, 10, 10);
  border-radius: 50% 20% / 10% 40%;
  padding: 3px 10px 3px 10px;
  margin: 0px;
  color: whitesmoke;
  font-weight: bold;
}

.card {
  border-radius: 20px;
  height: 130px !important;
  background-color: lightblue;
  outline: solid lightblue;
  outline-width: 2px;

}

.customimg {
  border-radius: 20px;
  height: 130px !important;

}

#ch {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  justify-content: space-around;

}

.title {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: black;



}
</style>
