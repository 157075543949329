<template>
    <div style="display: flex;
              overflow-x: scroll;
              overflow-y: hidden;
              scrollbar-width: none;
              scrollbar-color: transparent;
            " ref="lp" v-on:scroll="Xloca">
        <div class="divbook" v-for="c in categories" :key="c">
            <router-link :to="{
                path: '/bookcategory',
                name: 'BookCategory',
                components: 'BookCategory',
                params: { catid: c.id },
            }">
                <div class="catbook" style="background-color: #0b3c67">
                    {{ c.name.split("-")[0] }}
                </div>
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
    props: ["categories", "lstbooks"],
    data() {
        return {
            scrollleft: 0,
        }
    },
    methods: {
        Xloca(event) {
            const { target } = event;
            this.scrollleft = target.scrollLeft;
        },

    },

    activated() {
        if (this.scrollleft > 0) {
            this.$refs.lp.scrollLeft = this.scrollleft;
        }
    },
}
</script>
<style scoped>
#divbook {
    padding: 0px 10px 0px 0px;
}

.catbook {
    white-space: nowrap;
    margin: 5px;
    border-radius: 9px;
    padding: 10px;

    text-align: center;
    color: white;
    font-size: 17px;
    font-weight: bold;
}
</style>