<template>
  <div class="container">
    <div v-if="!connected">
      <CInternetConnection />
    </div>
    <div v-else>
      <div v-if="!asyncStatus_ready">
        <CWaiting />
      </div>
      <div v-else>
        <div class="container" v-if="book">
          <TitleComponent :title="book.title" :image="book.image.replace('/image', '/images')" />

          <div v-if="!msg_error && !isexist" class="col-12 mb-2">
            <div style="
                                                                                display: flex;
                                                                                justify-content: space-between;
                                                                                font-size: 13px;
                                                                              ">
              <p>{{ lang.lbprice }} {{ book.price }}$</p>
              <p v-show="discountprice > 0" :title="book.discounted_price + '% OFF'">
                {{ lang.lbdiscount }} ({{ book.discounted_price }}%)
              </p>
              <p>{{ lang.lbvat }} (10%)</p>
              <p title="Total Price" style="font-weight: bold">
                {{ lang.lbtotal }} {{ totalprice }}$
              </p>
            </div>

            <button type="button" class="btn btn-default" style="
                                                                                font-weight: bold;
                                                                                width: 100%;
                                                                                background-color: #0b3c67;
                                                                                color: white;
                                                                              " @click="onSubmitUSD()"> <i
                class="fa fa-cart-shopping"></i>
              {{ (totalprice > 0) ? lang.lbcheckout + " " + totalprice + " $" :
                lang.lbfree
              }}
            </button>
          </div>
          <div v-else class="col-12 mb-2">
            <CustomButtom @customChange="readBook" :book_audio="book.no_audio" />
          </div>
          <div v-if="book.ann_text" v-bind:class="
            'alert alert-dismissible fade show  ' + 'alert-' + book.ann_type
          ">
            <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
            <strong> {{ book.ann_text }}</strong>
          </div>
          <ul
            style="font-weight: bold; margin: 25px; text-align: center;display: flex; align-items: center; justify-content: center;">
            <li v-bind:class="!showauthor ? 'active' : ''" @click="showauthor = false">
              {{ lang.lbaboutbook }}
            </li>
            <li v-bind:class="showauthor ? 'active' : ''" @click="showauthor = true">
              {{ lang.lbauthor }}
            </li>
          </ul>

          <div ref="desbox">
            <p @click="showreadbut" v-html="!showauthor ? book.description : book.author" />
          </div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>
<script>
/* eslint-disable no-unused-vars */
import Footer from "@/layouts/Footer.vue";
import getbooks from "@/composable/networkrequest";
import CustomButtom from "@/components/CustomButtom.vue";
import TitleComponent from "@/components/TitleComponent.vue";
import CWaiting from "@/components/CWaiting.vue";
import CInternetConnection from "@/components/CInternetConnection.vue";
const axios = require("axios").default;
import { mapState } from "vuex";
export default {
  props: ["id"],
  setup(props) {
    //return { mbook, chapters };
  },
  async created() {
    if (this.connected) {

      await this.$store.dispatch("getBookbyid", this.id);

      //await this.checkexistbookpurchase();
      this.setprice(this.book.price);
    }
    this.asyncDatafetchedStatus();
  },
  computed: {
    ...mapState({
      book: (state) => state.book,
    }),
  },
  components: {
    CustomButtom,
    TitleComponent,
    CWaiting,
    CInternetConnection,
    Footer,
  },
  methods: {
    showreadbut() {
      try {
        if (this.$refs.desbox.clientHeight < 90) {
          return false;
        } else {
          return true;
        }
      } catch (error) {
        console.log(error);
      }
    },
    countWords(str) {
      str = str.replace(/(^\s*)|(\s*$)/gi, "");
      str = str.replace(/[ ]{2,}/gi, " ");
      str = str.replace(/\n /, "\n");
      return str.split(" ").length;
    },
    readmore() {
      this.readm = !this.readm;
    },
    readBook(value) {
      if (value === 0) {
        this.$router.push("/listenbook");
      }
      if (value === 1) {
        this.$router.push("/readbook");
      }
    },
    async checkexistbookpurchase() {
      try {
        await axios
          .post(
            this.$store.state.apiurl + "checkexistbook",
            JSON.stringify(
              { bookid: this.book.id, userid: this.profile.id, miniversion: this.miniversion },
              this.jsonheader
            )
          )
          .then((response) => {
            const result = response.data;
            if (result.status == true) {
              this.msg_error = result.message;
            }
            this.isexist = result.status;
          });
      } catch (error) {
        console.log(error);
      }
    },
    async recordPurchase(obj) {
      obj.miniversion = this.appversion;
      this.notasyncDatafetchedStatus();
      try {
        axios.defaults.headers.common['Authorization'] = this.token;

        await axios
          .post(
            this.$store.state.apiurl + "purchase",
            JSON.stringify(obj)
          )
          .then((response) => {
            const result = response.data;
            if (result.status == false) {
              this.msg_error = result.message;
            } else {
              this.$store.dispatch("mylibrarylst", this.profile.id);
              //this.ispurchasesuccess = true;
              this.$router.replace("/ordersuccess/" + this.book.id);
            }
          });
        //query the library
      } catch (error) {
        console.log(error);
      }
      this.asyncDatafetchedStatus();
    },
    onSubmitUSD() {
      if (this.token) {
        if (this.isexist == false) {
          if (process.env.VUE_APP_ISDEBUG == "false" && this.totalprice > 0) {
            this.$store.state.objcheckout = this.book;
            this.$router.push("/checkout");
          } else {
            //record payment here
            const mnote = { status: "success", transactionId: "3439252189" };
            const pdata = {
              id: this.book.id,
              price: this.totalprice,
              userid: this.profile.id,
              transactionid: this.invid,
              inv: this.invid,
              note: mnote,
            };
            this.recordPurchase(pdata);
          }
        }


      }
    },
    cat(id) {
      return this.$store.state.bookcategories.find(
        (category) => category.id === id
      );
    },

    setprice(price) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      if (price > 0) {
        this.discountprice = (price * this.book.discounted_price) / 100;
        this.priceafterdis = formatter.format(price - this.discountprice);
        this.vat = formatter.format(this.priceafterdis * 0.1);
        this.totalprice = (
          parseFloat(this.vat) + parseFloat(this.priceafterdis)
        ).toFixed(2);
      }
    },
    checkexistbook() {
      //check with the recent purchase book
      if(this.library && this.library.length>0){
      var abook = this.library.find((obj) => parseInt(obj.book_id) === parseInt(this.book.id
      ));
      if (abook != null && abook.id > 0) {
        this.isexist = true;
      } else this.isexist = false;
    }
      if (!this.isexist) {
        //check with current channel
        if (this.currentchannel != null && this.currentchannel.id > 0) {
          if (this.mychannels.length > 0) {
            var ch = this.mychannels.find((obj) => parseInt(obj.channel_id) === parseInt(this.currentchannel.id));
            if (ch != null && ch.id > 0) {
              console.log("Find in channel");
              this.isbought = true;
              var ab = this.currentchannelbooks.find((obj) => parseInt(obj.id) === parseInt(this.book.id));
              if (ab != null && ab.id > 0) {
                this.isexist = true;
              } else this.isexist = false;
            }
          }
        }
      }

    }
  },

  data() {
    return {
      readm: false,
      showauthor: false,
      books: [],
      msresult: null,
      msg_error: null,
      musicSrck: "https://s3.web141.com/anuksa/mysong.mp3",
      note: null,
      txid: null,
      invid: "BKINV" + Date.now(),
      isexist: false,
      discountprice: 0.0,
      priceafterdis: 0.0,
      vat: 0,
      totalprice: 0.0,
      desheight: 0,
      displaytext: "",
    };
  },
  watch: {
    book() {
      this.checkexistbook();
    }
  },
  mounted() {
    if (process.env.VUE_APP_ISDEBUG == "false") {
      this.$bridge.registerHandler("getStatus", (data) => {
        if (data != "" && data != null) {
          const pdata = {
            id: this.book.id,
            price: this.totalprice,
            userid: this.profile.id,
            transactionid: data.transactionId ? data.transactionId : data.trxId,
            inv: this.invid,
            note: data,
            miniversion: this.appversion,
          };
          this.recordPurchase(pdata);

        }

        /*this.trackabasentback(data);
        if (data.transactionId) {
          this.txid = data.transactionId;
        }
        if (data.trxId) {
          this.txid = data.trxId;
        }
        this.note = data;
        if (this.txid) {
          const pdata = {
            id: this.book.id,
            price: this.totalprice,
            userid: this.profile.id,
            transactionid: this.txid,
            inv: this.invid,
            note: this.note,
          };
          this.recordPurchase(pdata);
        }*/
      });
    }

  },
};
</script>
<style scoped>
li.active {
  border-bottom: 2px solid #0b3c67;
  color: #0b3c67;
}

li {
  margin: 5px;
  padding: 5px;
  color: lightslategray;
}

.descontent {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 1.9rem;
  min-height: auto;
  max-height: 5.7rem;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 5px;
}

.readmore {
  height: auto;
  line-height: 1.9rem;
}

#spread {
  float: right;
  padding: 10px;
  margin-bottom: 3.5rem;
}
</style>