<template>
  <div style="
                                                  justify-content: center;
                                                  align-items: center;
                                                  display: flex;
                                                  text-align: center;
                                                  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);  ">
    <div class="row">

      <div class="col">
        <div style="width: 100%">
          <p class="h5">{{ lang.lbuysuccess }}</p>
          <img :src="isLoaded
              ? media_url + channel.profile.replace('/image', '/images')
              : lazyimage
            " class="img" style="width: 170px; height: auto; margin-bottom: 20px" @error="replaceByDefault"
            @load="loadImage" />

          <div style="padding-bottom: 20px">{{ channel.name }}</div>
          <button class="btn actionbut" @click="readbook()">
            {{ lang.lbreadbook }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["chid"],
  created() { },
  data() {
    return {
      channel: this.$store.state.objcheckout,

    };
  },
  methods: {
    readbook() {
      const ch = this.channels.find((channel) => parseInt(channel.id) === parseInt(this.chid));
      this.$router.replace("/channeldetail/" + ch.code);
    },
  },
  mounted() {
    window.onpopstate = () => {
      this.$bridge.callHandler("backToHomePage");
    };
  }

};
</script>

<style scoped>
div.card {
  margin-top: -2em;
  position: fixed;
  font-weight: bolder;
  align-content: center;
  width: 50%;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

p {
  padding-bottom: 1em;
  padding-top: 1em;
}

.img {
  border-radius: 20px;
}
</style>